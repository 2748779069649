const data = {
    ru: {
        main: {
            head: {
                title: 'ЦЕНТР СТРАТЕГИЧЕСКОГО РАЗВИТИЯ АРМЕНИИ',
                subtitle: `Фонд «Центр стратегического развития Республики Армения» создан с целью содействия увеличению темпов экономического роста Республики Армения посредством внедрения лучших мировых практик государственного управления и&nbsp;институционального развития.`
            },

            history: {
                title: 'ИСТОРИЯ СОЗДАНИЯ ФОНДА',
                text1: `В сентябре 2022 года был учрежден Фонд «Центр стратегического развития Республики Армения», учредителями которого выступили: с&nbsp;российской стороны — <a href="http://www.vavt.ru/" target="_blank">Федеральное государственное бюджетное образовательное учреждение высшего образования «Всероссийская академия внешней торговли Министерства экономического развития Российской Федерации»</a> и&nbsp;с&nbsp;армянской стороны — <a href="https://dica.am/ru/" target="_blank">Корпорация Развития и Инвестиций Армении</a>.`,
                text2: `Фонд учрежден на&nbsp;основе Меморандума, подписанного между <a href="https://www.mineconomy.am/ru" target="_blank">Министерством экономики Республики Армения</a> и&nbsp;<a href="https://www.economy.gov.ru/" target="_blank">Министерством экономического развития Российской Федерации</a> в&nbsp;июне 2022 года на&nbsp;полях Петербургского международного экономического форума.`,
            },

            goals: {
                title: 'ЦЕЛИ ФОНДА',
                list: [
                    `Содействие экономическому и&nbsp;институциональному развитию Республики Армения`,
                    `Содействие развитию торгово-экономического сотрудничества между Республикой Армения, Российской Федерацией и другими партнерами по ЕАЭС`,
                    `Повышение отдачи от евразийской интеграции`
                ]
            },

            tasks: {
                title: 'ЗАДАЧИ ФОНДА',
                list: [
                    `Внедрение лучших российских и&nbsp;мировых практик государственного управления и&nbsp;институционального развития`,
                    `Разработка долгосрочных отраслевых стратегий и&nbsp;программ Республики Армения`,
                    `Разработка инвестиционных проектов, отвечающих приоритетам развития Республики Армения и&nbsp;ЕАЭС`,
                    `Развитие промышленной кооперации с&nbsp;Россией и&nbsp;партнерами по&nbsp;ЕАЭС`,
                    `Мониторинг барьеров, препятствующих развитию торгово-экономического сотрудничества между Республикой Армения и Российской Федерацией, разработка предложений по&nbsp;их&nbsp;преодолению`,
                ]
            },

            documents: {
                title: 'ДОКУМЕНТЫ',
                list: [
                    {
                        name: 'Устав фонда',
                        link: 'Устав_ЦСРА.pdf',
                    },
                    {
                        name: 'Свидетельство о&nbsp;государственной регистрации',
                        link: 'Свидетельство_о_регистрации_ЦСРА.pdf',
                    },
                ]
            },

            projects: {
                title: 'ПРОЕКТЫ',
                list: [
                    {
                        img: 'project1.png',
                        name: 'Разработка государственной программы Республики Армения по&nbsp;созданию новых школ с&nbsp;применением механизмов государственно-частного партнерства',
                        description: `Анализ лучших мировых и российских практик развития государственно-частного партнерства в сфере образования. Определение препятствий для государственно-частного партнерства в сфере образования в Республике Армения. Выработка рекомендаций по распространению лучших мировых и российских практик в Армении, применимых для преодоления выявленных препятствий. Разработка государственной программы Республики Армения по созданию новых школ с применением механизмов государственно-частного партнерства.`
                    },

                    {
                        img: 'project2.png',
                        name: 'Оценка целесообразности и&nbsp;выработка предложений по&nbsp;формированию Единого института развития Республики Армения',
                        description: 'Анализ мирового опыта создания и&nbsp;функционирования институтов развития по&nbsp;продвижению и&nbsp;поддержке промышленной кооперации и&nbsp;экспорта в&nbsp;целях разработки рекомендаций для совершенствования и,&nbsp;при необходимости, создания такого института развития в&nbsp;Республике Армения.'
                    },

                    {
                        img: 'project3.png',
                        name: 'Подготовка проекта государственной программы Республики Армения по&nbsp;строительству индустриальных парков',
                        description: 'Оценка потребностей страны в&nbsp;строительстве новых индустриальных парков по&nbsp;отдельным видам отраслей. Идентификация отраслей, для&nbsp;которых формирование индустриальных парков обеспечит наибольший эффект (на&nbsp;примере экономики Республики Армения) и&nbsp;разработка концепции государственной программы по&nbsp;строительству индустриальных парков. Оценка и&nbsp;анализ перспектив сотрудничества в&nbsp;рамках ЕАЭС по&nbsp;этому направлению.'
                    },

                    {
                        img: 'project4.png',
                        name: 'Содействие развитию благоприятного инвестиционного климата',
                        description: 'Анализ инвестиционного климата страны, включая концессионное законодательство, регулирование государственно-частного партнерства и&nbsp;разработка предложений по&nbsp;его&nbsp;улучшению. Оценка экономических эффектов от&nbsp;предлагаемых изменений.'
                    },
                ]
            },

            partners: {
                title: 'ПАРТНЕРЫ',

                list: [
                    {
                        img: 'logo1.png',
                        desc: 'Национальный Политехнический Университет Армении'
                    },

                    {
                        img: 'logo2.png',
                        desc: 'Энтерпрайз Армения'
                    },

                    // {
                    //     img: 'logo3.png',
                    //     desc: 'Министерство экономического развития Российской Федерации'
                    // },

                    {
                        img: 'logo4.png',
                        desc: 'МКДЦ «Дом Москвы»'
                    },

                    // {
                    //     img: 'logo5.png',
                    //     desc: 'Министерство Экономики Республики Армения'
                    // },

                    {
                        img: 'logo6.png',
                        desc: 'Армянский государственный экономический университет'
                    },

                    // {
                    //     img: 'logo7.png',
                    //     desc: 'Всероссийская академия внешней торговли Министерства экономического развития Российской Федерации'
                    // },

                    {
                        img: 'logo8.png',
                        desc: 'Российско-Армянский Университет'
                    },

                    {
                        img: 'logo9.png',
                        desc: 'Ереванский Государственный Университет'
                    },

                ],
                btn: 'Стать партнером',


                form: {
                    placeholders: {
                        fio: 'ФИО *',
                        position: 'Должность',
                        company: 'Организация *',
                        country: 'Страна регистрации *',
                        partnership: 'Сферы сотрудничества *',
                        phone: 'Номер телефона',
                        email: 'Электронная почта *',
                    },

                    messages: {
                        empty: 'Поле обязательно для заполнения',
                        email: 'E-mail должен быть вида example@domain.com',
                        phone: 'Допускаются только + и цифры. Длина номера должна быть от 9 до 15 символов.'
                    },

                    result: {
                        success: `Спасибо! <br/> Ваше сообщение отправлено.`,
                        error: `Что-то пошло не так, <br/> попробуйте снова.`
                    },

                    checkbox: 'Я даю согласие на&nbsp;обработку моих персональных данных и&nbsp;соглашаюсь с&nbsp;политикой конфиденциальности'
                },
            },
        },

        support: {
            title: 'Ресурсы поддержки предпринимателей',

            topics: {
                msp: {
                    img: 'logo1.png',
                    list: [
                        {
                            title: 'Регистрация бизнеса',
                            links: [
                                {
                                    name: 'Регистрация бизнеса в&nbsp;России',
                                    link: 'https://xn--l1agf.xn--p1ai/services/businessRegistration/'
                                },

                                {
                                    name: 'Выбор организационно-правовой формы',
                                    link: 'https://xn--l1agf.xn--p1ai/services/opf/'
                                },

                                {
                                    name: 'Получение электронной подписи',
                                    link: 'https://xn--l1agf.xn--p1ai/services/ukep/'
                                },
                            ]
                        },

                        {
                            title: 'Меры поддержки предпринимателей',
                            links: [
                                {
                                    name: 'Меры поддержки малого и&nbsp;среднего бизнеса в&nbsp;России доступные для&nbsp;бизнеса из&nbsp;стран СНГ',
                                    link: 'https://xn--l1agf.xn--p1ai/services/support/'
                                },
                            ]
                        },

                        {
                            title: 'Государственные закупки',
                            links: [
                                {
                                    name: 'Участие МСП в&nbsp;государственных закупках в&nbsp;России (до 500 тыс. руб.)',
                                    link: 'https://xn--l1agf.xn--p1ai/services/purchase_access/'
                                },
                            ]
                        },

                        {
                            title: 'Проверка контрагента',
                            links: [
                                {
                                    name: 'Проверка контрагента',
                                    link: 'https://xn--l1agf.xn--p1ai/services/counterparty/ '
                                },
                            ]
                        },

                        {
                            title: 'Поиск контрагента в России',
                            links: [
                                {
                                    name: 'Реестр российских компаний, предлагающих товары для&nbsp;продажи',
                                    link: 'https://xn--l1agf.xn--p1ai/services/development/'
                                },

                                {
                                    name: 'Реестр российских компаний, которые ищут поставщиков товаров',
                                    link: 'https://xn--l1agf.xn--p1ai/services/development/'
                                },
                            ]
                        },
                    ]
                },

                rec: {
                    img: 'logo2.png',
                    list: [
                        {
                            title: 'Импорт-экспорт товаров',
                            links: [
                                {
                                    name: 'Получение разъяснений относительно обязательных требований при&nbsp;импорте товаров из&nbsp;России, а&nbsp;также экспорте товаров на&nbsp;российский рынок',
                                    link: 'https://cutt.ly/fMe683I'
                                },

                                {
                                    name: 'Образец экспортного контракта для&nbsp;поставки товара в&nbsp;Россию',
                                    link: 'https://cutt.ly/sMe5Hm1'
                                },


                                {
                                    name: 'Логистика товаров на&nbsp;рынки различных регионов РФ',
                                    link: 'https://cutt.ly/JMe5CQZ'
                                },
                            ]
                        },
                    ]
                },

                // mpt: {
                //     img: 'logo3.png',
                //     list: [
                //         {
                //             title: 'Заявка на проект в России',
                //             links: [
                //                 {
                //                     name: 'Оставить заявку на экспортный или инвестиционный проект в России',
                //                     link: 'https://arm.minpromtorg.gov.ru/'
                //                 },
                //             ]
                //         },
                //     ]
                // }
            },

            registration: {
                title: `Обратиться в&nbsp;Центр стратегического развития Республики Армения в&nbsp;случае возникновения препятствий при&nbsp;ведении бизнеса <br/>в&nbsp;России или&nbsp;Армении`,
                categories: {
                    desc: 'Отправить обращение как',
                    jPerson: 'Юридическое лицо',
                    phPerson: 'Физическое лицо'
                },

                placeholdersMain: {
                    surname: 'Фамилия *',
                    name: 'Имя *',
                    patronym: 'Отчество',
                    email: 'Электронная почта *',
                    phone: 'Номер телефона',
                    company: 'Название компании *',
                    text: 'Сообщение *'
                },
                types: {
                    placeholder: 'Выбор вида обращения *',
                    options: [
                        'Предложение',
                        'Проблема'
                        // {
                        //     value: 'suggestion',
                        //     label: 'Предложение'
                        // },
                        //
                        // {
                        //     value: 'problem',
                        //     label: 'Проблема'
                        // },
                    ],
                },


                placeholders: {
                    fio: 'ФИО *',
                    company: 'Наименование компании *',
                    position: 'Должность',
                    country: 'Страна регистрации *',
                    phone: 'Номер телефона',
                    email: 'Электронная почта *',
                    text: 'Вопрос *'
                },

                messages: {
                    empty: 'Поле обязательно для заполнения',
                    email: 'E-mail должен быть вида example@domain.com',
                    phone: 'Допускаются только + и цифры.'
                },

                result: {
                    success: `Спасибо! <br/> Ваше сообщение отправлено.`,
                    error: `Что-то пошло не так, <br/> попробуйте снова.`
                },

                checkbox: 'Я даю согласие на обработку моих персональных данных и соглашаюсь с политикой конфиденциальности'
            },
        },

        experts: {
            title: 'Эксперты',
            director: {
                fio: 'Карен Авагян',
                desc: `Директор Фонда <br/> «Центр стратегического развития <br/> Республики Армения»`,
                text: [
                    `Родился в Ереване в 1979 году`,
                    `В 2002 году окончил факультет Экономики Российского государственного технологического университета
                        им. К. Э. Циолковского» по&nbsp;специальности «Экономика и&nbsp;управление»`,
                    `Выпускник бизнес-школы «Сколково» 2018 года`,
                    `До 2004 года занимал должность заместителя руководителя отдела внешнеэкономической деятельности
                        официального представительства Daewoo Heavy Industries в&nbsp;России, г. Москва`,
                    `До 2013 года занимал должность коммерческого директора Hyundai Mobis CIS, г. Москва`,
                    `До 2019 года сооснователь проектов в&nbsp;области информационных технологий и&nbsp;машинного обучения, г.
                        Москва`,
                    `До 2022 года консультировал международные компании в&nbsp;сфере машиностроения и&nbsp;промышленности, г. Ереван`,
                    `С сентября 2022 года директор Фонда «Центр стратегического развития Республики Армения»`,
                ]
            },
            list: [
                {
                    img: 'photo1.png',
                    name: 'Паргев Тонапетян',
                    description: 'Профессор кафедры «Транспортные средства и организация дорожного движения» Национального Политехнического университета Армении'
                },

                {
                    img: 'photo2.png',
                    name: 'Генрик Кочинян',
                    description: 'Директор кафедры «Транспортные процессы и организация дорожного движения» Национального Политехнического университета Армении'
                },

                {
                    img: 'photo3.png',
                    name: 'Григор Назарян',
                    description: 'Декан факультета регулирования экономики и международных экономических отношений Армянского государственного экономического университета'
                },

                {
                    img: 'photo4.png',
                    name: 'Сергей Календжян',
                    description: 'д.э.н., DBA, профессор, заслуженный экономист РФ. Основатель и декан ВШКУ РАНХиГС. Почетный доктор Европейского университета (Швейцария) и&nbsp;Европейского института международного менеджмента (Франция). Кавалер ордена Дружбы.'
                },

                {
                    img: 'photo5.png',
                    name: 'Ольга Гумилевская',
                    description: 'DBA. Зам. декана ВШКУ РАНХиГС по&nbsp;международному развитию и&nbsp;аккредитациям. Преподаватель кафедры корпоративного управления ВШКУ РАНХиГС, бизнес-тренер.'
                },

                {
                    img: 'photo8.png',
                    name: 'Диана Армаганян',
                    description: 'Эксперт Центра стратегического развития Армении, Маркетолог'
                },

                {
                    img: 'photo9.png',
                    name: 'Мариам Восканян',
                    description: 'Д.Э.Н., Доцент, Заведующая кафедрой экономики и финансов <br/> Российско-Армянского Университета'
                },

            ]
        },

        faq: {
            title: 'FAQ&nbsp;для&nbsp;предпринимателей из&nbsp;Армении по&nbsp;ведению бизнеса и&nbsp;ВЭД с&nbsp;Россией',
            list: [
                {
                    name: 'Какими нормативными требованиями необходимо руководствоваться для поставки в Россию армянских фруктовых вин?',
                    text: [
                        `Армянские фруктовые «вина» с 1 января 2022 года могут обращаться на рынке Российской Федерации, в соответствии с&nbsp;требованиями <a href="https://base.garant.ru/10105489/" target="_blank">Федерального закона от 22.11.1995 № 171-ФЗ</a>, при условии исключения из названия слова «вино».`,
                        `С 1 января 2022 года вступили в силу положения <a href="http://ivo.garant.ru/#/document/73355393/paragraph/2" target="_blank">Федерального закона от 27.12.2019
                        № 468-ФЗ «О виноградарстве и&nbsp;виноделии в Российской Федерации»</a> согласно которым
                        использование слова «вино» и производных от него слов в&nbsp;отношении продукции, 
                        полученной от брожения каких-либо фруктов кроме винограда, будет считаться
                        фальсификацией (пункт 4.1. статьи 26).`,
                        `Такая продукция может обращаться с наименованием «плодовая алкогольная продукция»`,
                        `При этом неизменными сохранились требования к продукции, определенные
                        межгосударственным стандартом <a target="_blank" href="http://ivo.garant.ru/#/document/71680264/paragraph/1/doclist/2615/showentries/0/highlight/JTVCJTdCJTIybmVlZF9jb3JyZWN0aW9uJTIyJTNBZmFsc2UlMkMlMjJjb250ZXh0JTIyJTNBJTIyJTVDdTA0MTMlNUN1MDQxZSU1Q3UwNDIxJTVDdTA0MjIlMjAzMzgwNi0yMDE2JTIwJTVDdTA0MTIlNUN1MDQzOCU1Q3UwNDNkJTVDdTA0MzAlMjAlNUN1MDQ0NCU1Q3UwNDQwJTVDdTA0NDMlNUN1MDQzYSU1Q3UwNDQyJTVDdTA0M2UlNUN1MDQzMiU1Q3UwNDRiJTVDdTA0MzUlMjAlNUN1MDQ0MSU1Q3UwNDQyJTVDdTA0M2UlNUN1MDQzYiU1Q3UwNDNlJTVDdTA0MzIlNUN1MDQ0YiU1Q3UwNDM1JTIwJTVDdTA0MzglMjAlNUN1MDQzMiU1Q3UwNDM4JTVDdTA0M2QlNUN1MDQzZSU1Q3UwNDNjJTVDdTA0MzAlNUN1MDQ0MiU1Q3UwNDM1JTVDdTA0NDAlNUN1MDQzOCU1Q3UwNDMwJTVDdTA0M2IlNUN1MDQ0YiUyMCU1Q3UwNDQ0JTVDdTA0NDAlNUN1MDQ0MyU1Q3UwNDNhJTVDdTA0NDIlNUN1MDQzZSU1Q3UwNDMyJTVDdTA0NGIlNUN1MDQzNSUyMCU1Q3UwNDQxJTVDdTA0NDIlNUN1MDQzZSU1Q3UwNDNiJTVDdTA0M2UlNUN1MDQzMiU1Q3UwNDRiJTVDdTA0MzUuJTIwJTVDdTA0MWUlNUN1MDQzMSU1Q3UwNDQ5JTVDdTA0MzglNUN1MDQzNSUyMCU1Q3UwNDQyJTVDdTA0MzUlNUN1MDQ0NSU1Q3UwNDNkJTVDdTA0MzglNUN1MDQ0NyU1Q3UwNDM1JTVDdTA0NDElNUN1MDQzYSU1Q3UwNDM4JTVDdTA0MzUlMjAlNUN1MDQ0MyU1Q3UwNDQxJTVDdTA0M2IlNUN1MDQzZSU1Q3UwNDMyJTVDdTA0MzglNUN1MDQ0ZiUyMiU3RCU1RA==">ГОСТ 33806-2016 «Вина фруктовые столовые
                        и виноматериалы фруктовые столовые. Общие технические условия»</a>`,
                    ],
                    category: 'ВЭД. Импорт товаров в Россию',
                    tags: [
                        'Доступ товаров',
                        'Фрукты',
                        'Вино',
                    ]
                },

                {
                    name: `На какие сроки установлен запрет на экспорт мясных и молочных
                        продуктов из Армении?`,
                    text: [
                        `<a href="https://fsvps.gov.ru/sites/default/files/npa-files/2016/01/20/tu_armeniya_20._01_6347173307_fs-en-8_634_20_01_2016.pdf" target="_blank">Указанием Россельхознадзора от 20 января 2016 г.</a> установлен запрет на ввоз
                            в Российскую Федерацию мясной и молочной продукции с территории Республики
                            Армения.`,
                        `Российской Федерацией в 2016 г. был получен официальный статус Всемирной
                            организации здравоохранения животных (МЭБ) страны с зоной свободной от ящура без
                            вакцинации.`,
                        `Импорт живых животных и животноводческой продукции должен осуществляться
                            в соответствии с положениями Кодекса МЭБ. Страны, не имеющие официального статуса
                            свободы, признаются неблагополучными по отношению к ящуру.`,
                        `Армения в настоящее время не имеет статуса МЭБ свободы от ящура и рассматривается
                            МЭБ как неблагополучная.`,
                        `В отсутствии такого признания применяется статья 8.8.23 («Рекомендации по импорту
                            из страны или зоны, заражённой ящуром»): мясные продукты должны быть обработаны по
                            технологии, обеспечивающей разрушение вируса ящура одним из способов, описанных
                            2 в Статье 8.8.31. Согласно статье 8.8.31 к таким процедурам относятся аппертизация, 
                            проварка или обезвоживание после просаливания.`,
                        `Россельхознадзор разрешает ввоз необработанной в соответствии с указанными
                            рекомендациями продукции, исключительно на территорию Российской Федерации, 
                            имеющей статус – зоны наблюдения с вакцинацией.`,
                        `В настоящее время экспорт продукции из Армении сопряжен со значительными
                            эпидемиологическими рисками (завоз ящура в Россию), в связи с чем завоз продукции
                            возможен в случае выполнения необходимых санитарных процедур (обработки).`,
                        `Отмена ограничений возможна при условии получения Арменией статуса страны, 
                            свободной от ящура.`,
                        `В рамках ЕАЭС с учетом рекомендаций кодексов МЭБ установлен порядок взаимодействия
                            государств-членов при&nbsp;профилактике, диагностике, локализации и ликвидации очагов
                            особо опасных, карантинных и зоонозных болезней животных.`,
                    ],
                    category: 'ВЭД. Импорт товаров в Россию',
                    tags: [
                        'Экспорт',
                    ]
                },

                {
                    name: `Можно ли вывозить промышленные товары и продукцию из России
                            за границу?`,
                    text: [
                        `<a href="https://base.garant.ru/403681896/" target="_blank">Постановления Правительства Российской Федерации от 9 марта 2022 г. № 311, 312, 313</a>
                            регулируют порядок вывоза товаров и оборудования.`,
                        `В настоящее время вывоз иностранной продукции за пределы России, за исключением
                            территории ЕАЭС, Абхазии и&nbsp;Южной Осетии, запрещен.`,
                        `В отношении продукции российского производства ограничений нет. Товары могут
                            вывозиться как на территории третьих стран, так и на территорию ЕАЭС при наличии
                            сертификата по форме СТ-1, или иного сертификата, подтверждающего происхождение
                            товара, а также соответствующего заключения Минпромторга России.`,
                    ],
                    category: 'ВЭД. Экспорт товаров из России',
                    tags: [
                        'ВЭД',
                        'Импорт',
                    ]
                },

                {
                    name: `Как организовано движение на пункте пропуска «Верхний Ларс»?`,
                    text: [
                        `<a href="https://base.garant.ru/402826843/" target="_blank">Постановлением Правительства Российской Федерации от 18 сентября 2021 № 1576</a>
                            в рамках реконструкции МАПП «Верхний Ларс» (на период проведения реконструкции, 
                            но не позднее 31 декабря 2023 г.) установлены временные места оформления товаров, 
                            не являющихся местами перемещения этих товаров через границу Российской
                            Федерации, ввозимых или вывозимых автомобильным транспортом (кроме животных) (1. Республика Северная Осетия - Алания, в районе н.п. Верхний Ларс, 2. Республика
                            Северная Осетия - Алания, г. Владикавказ, в районе н.п. Ларс на 22 км справа от а/д "Владикавказ - Н.Ларс").`,
                        `После реконструкции, ожидается, что пропускная способность МАПП «Верхний Ларс» 
                            составит около 3 000 транспортных средств в сутки.`,
                        `С июня 2022 г. достигнута договорённость об открытии дополнительного места, 
                            в котором допускается прибытие в&nbsp;Российскую Федерацию и убытие из Российской
                            Федерации товаров (за исключением животных), ввозимых в&nbsp;Российскую Федерацию
                            и вывозимых из Российской Федерации автомобильным транспортом через МАПП
                            «Верхний Ларс» - ООО «Южные ворота» (Республика Северная Осетия - Алания, 
                            г. Владикавказ, с. Чми, ул. Дударова, д. 1а., а также складской комплекс по данному адресу.).`,
                        `ООО «Южные ворота» предполагается использовать исключительно до полного
                            завершения реконструкции «Верхнего Ларса».`,
                        `<b>Справочно. Таможенное оформление состоит из 5 основных этапов:</b>`,
                        `<ul>
                           <li>Регистрация декларации на товары (ДТ);</li>
                           <li>Прохождение документального контроля, во время которого производится
                                проверка всех представленных декларантом документов: ДТ, ДТС (декларация
                                таможенной стоимости), КТС (корректировка таможенной стоимости), кроме этого
                                проверяются также коды ТН ВЭД, и страна происхождения товара;</li>
                           <li>Прохождение валютного контроля, во время которого идет проверка финансовых
                                документов, к их числу относится: контракт с уникальным номером (присваивается
                                за 1 день). Проверяется также правильность указанных таможенных платежей, 
                                представленных к уплате;</li>
                           <li>Уплата таможенных платежей (пошлины, таможенные сборы, налоги, сборы
                                на выдачу лицензий и иные платежи, предусмотренные законодательством при
                                таможенном оформлении груза);</li>
                        </ul>`,
                        `Прохождение линии таможенного контроля, где происходит общая заключительная
                                проверка правильности проведенной растаможки.`
                    ],
                    category: `ВЭД. Транспортная логистика`,
                    tags: [
                        'Логистика',
                        'Транспорт',
                    ]
                },

                {
                    name: `О ситуации с тарифами на авиаперелеты между Россией и Арменией`,
                    text: [
                        `Сторонами достигнута договоренность о снятии всех ограничений по количеству пунктов
                            назначения на территории Российской Федерации и Республики Армения, по количеству
                            назначенных авиаперевозчиков и по количеству выполняемых частот полетов, а также по
                            емкостям используемых типов воздушных судов для авиапредприятий сторон.`,
                        `Вопросы гармонизации тарифов и ставок обсуждаются на площадке Евразийской
                            экономической комиссии в рамках реализации Основных направлений и этапов
                            скоординированной транспортной политики на 2021 – 2023 годы.`,
                    ],
                    category: 'ВЭД. Транспортная логистика',
                    tags: [
                        'Авиасообщение',
                        'Логистика',
                        'Транспорт',
                    ]
                },

                {
                    name: `Как происходит таможенное оформление ценных грузов?`,
                    text: [
                        `При наличии прямых рейсов в страны ЕАЭС ценные грузы не декларируются таможенным
                            органам и не пломбируются ими, так как при перемещении грузов внутри ЕАЭС исключена
                            процедура их таможенного оформления, что значительно облегчает организацию
                            перевозок`,
                        `В соответствии с принятыми международными нормами перевозка ценностей
                            осуществляется авиационным транспортом, в частности регулярными рейсами
                            авиакомпаний, самолеты которых имеют необходимые условия для перевозки ценных
                            грузов, включая страховое покрытие.`,
                    ],
                    category: 'ВЭД. Таможенное оформление',
                    tags: [
                        'Доступ товаров',
                        'Таможенное оформление',
                        'Логистика',
                        'Транспорт',
                    ]
                },

                {
                    name: `Как получить разрешение (лицензию) на производство лекарственных
                            средств?`,
                    text: [
                        `Лицензирование производства лекарственных средств на территории России
                            осуществляет Минпромторг России, в том числе в электронной форме.`,
                        `В целях получения услуги юридические лица направляют в Минпромторг России
                            (Департамент развития фармацевтической и медицинской промышленности) следующие
                            документы:`,
                        `<ul>
                            <li>заявление о предоставлении лицензии;</li>
                            <li>подтверждение наличия необходимых помещений, зданий, сооружений, 
                                технических средств;</li>
                            <li>копии титульных листов промышленных регламентов;</li>
                            <li>опись документов.</li>
                        </ul>`,
                        `<strong>Лицензия предоставляется в течении 45 дней. Стоимость услуги – 7500 рублей.</strong>`,
                        `Юридическому лицу может быть отказано в предоставлении лицензии в случаях, если: 
                            представлена недостоверная или&nbsp;искаженная информации; соискатель не соответствует
                            требованиям.`,
                        //`<strong>Более подробная информация:</strong><br/><a href="https://minpromtorg.gov.ru/activities/services/licensing/1_8/1_8_2/м" target="_blank">https://minpromtorg.gov.ru/activities/services/licensing/1_8/1_8_2/м</a>`,
                    ],
                    category: 'Ведение бизнеса в России. Разрешительные документы',
                    tags: [
                        'Локализация',
                        'Производство',
                    ]
                },

                {
                    name: `Как найти потенциального поставщика из России?`,
                    text: [
                        `Вы можете найти потенциальных российских поставщиков используя Цифровую
                            платформу МСП.РФ.`,
                        `<b>Что необходимо сделать:</b>`,
                        `a) Зарегистрироваться на <a href="https://мсп.рф" target="_blank">Цифровой платформе МСП.РФ</a>`,
                        `b) Выбрать сервис <a href="https://xn--l1agf.xn--p1ai/services/development" target="_blank">«Производственная кооперация и сбыт»</a>`,
                        `На сервисе реализованы 2 реестра, где вы можете найти потенциальных
                            поставщиков и связаться с ними:`,
                        `<ul>
                            <li>В «Реестре промышленных компаний» содержатся сведения о российских
                                производителях непродовольственных товаров и промышленной
                                продукции с подтвержденным опытом поставок
                                <a href="https://мсп.рф/services/development/not-food/" target="_blank">https://мсп.рф/services/development/not-food/</a></li>
                            <li>В «Реестре поставщиков продуктов питания» собраны сведения
                                о проверенных российских поставщиках и&nbsp;производителях продуктов
                                питания <a href="https://мсп.рф/services/development/food/" target="_blank">https://мсп.рф/services/development/food/</a></li>
                        </ul>`,
                        `c) Вы также может разместить свой запрос на поиск поставщиков на Цифровой
                            платформе МСП.РФ. Для этого необходимо заполнить форму в формате Excel 
                            по образцу (прилагается) и направить ее сотрудникам АО «Корпорация «МСП» 
                            на почту <a href="mailto:b2b@corpmsp.ru">b2b@corpmsp.ru</a>. Мы разместим на площадке сервиса ваши потребности
                            и будем направлять отклики потенциальных поставщиков.`,
                        ``,
                        ``,
                    ],
                    category: 'Ведение бизнеса в России. Бизнес-коммуникации',
                    tags: [
                        'Производственная кооперация и сбыт',
                    ]
                },

                {
                    name: `Как найти потенциального покупателя в России?`,
                    text: [
                        `Вы можете найти текущие запросы крупных госкомпаний и частных компаний/торговых
                            сетей в России, используя сервисы на Цифровой платформе МСП.РФ и обратиться за
                            консультацией к сотрудникам АО «Корпорация «МСП».`,
                        `<strong>Чтобы найти закупки со стороны крупных госкомпаний, необходимо:</strong>`,
                        `a) Зарегистрироваться на <a href="https://мсп.рф" target="_blank">Цифровой платформе МСП.РФ</a>`,
                        `b) Выбрать сервис <a href="https://xn--l1agf.xn--p1ai/services/purchase_access/" target="_blank">«Доступ к закупкам крупных госкомпаний»</a>`,
                        `Сервис поможет вам найти закупки крупных государственных компаний.`,
                        `<i>Если вы нашли интересующую вас закупку, то свяжитесь с сотрудником АО «Корпорация
                            «МСП» по телефону 8 800 1001100  (доб. 160, 468, 397) или отправьте письмо на почту
                            <a href="mailto:b2b@corpmsp.ru">b2b@corpmsp.ru</a>.</i>`,
                        `<strong>Если вы хотите поставлять продукцию частным компаниями и торговым сетям
                            в России, то необходимо:</strong>`,
                        `a) Зарегистрироваться на <a href="https://мсп.рф" target="_blank">Цифровой платформе МСП.РФ</a>`,
                        `b) Выбрать сервис <a href="https://xn--l1agf.xn--p1ai/services/development" target="_blank">«Производственная кооперация и сбыт»</a>`,
                        `Если вы хотите поставлять непродовольственные товары и промышленную
                            продукцию торговым сетям и крупным производственным компаниям, то выберите
                            модуль <a href="https://мсп.рф/services/development/retail_noprod/" target="_blank">«Промышленным компаниям: стать поставщиком ритейлеров
                            и производителей»</a>`,
                        `Если вы хотите поставлять продовольственную продукцию и попасть на полки
                            крупнейших торговых сетей и «Фермерских островков», то выберите модуль
                            <a href="https://мсп.рф/services/development/retail/" target="_blank">«Поставщикам продуктов питания: попасть на полки торговых сетей»</a>`,
                        `<i>Если вы нашли интересующий вас запрос, то свяжитесь с сотрудником
                            АО «Корпорация «МСП» по телефону 8 800 100 1 100 (доб. 160, 468, 397) или отправьте
                            письмо на почту <a href="mailto:b2b@corpmsp.ru">b2b@corpmsp.ru</a>.</i>`,
                    ],
                    category: 'Ведение бизнеса в России. Бизнес-коммуникации',
                    tags: [
                        'Производственная кооперация и сбыт',
                        'Доступ к закупкам крупных госкомпаний',
                    ]
                },
            ],
        },

        seminar: {
            about: {
                title: `ЕВРОПЕЙСКИЙ МЕНЕДЖМЕНТ: СИСТЕМА ЭФФЕКТИВНОГО УПРАВЛЕНИЯ ОРГАНИЗАЦИЕЙ НА&nbsp;ОСНОВЕ ДЕЛЕГИРОВАНИЯ ПОЛНОМОЧИЙ И&nbsp;ОТВЕТСТВЕННОСТИ`,
                subtitle: `ДВУХДНЕВНЫЙ СЕМИНАР ДЛЯ РУКОВОДИТЕЛЕЙ АРМЯНСКИХ ПРЕДПРИЯТИЙ`,
                date: `17-18 ФЕВРАЛЯ 2023 ГОДА`,
                desc: `Национальный центр компетенций Армении, который организован фондом «Центр стратегического развития республики Армения» в рамках программы повышения производительности труда армянской экономики, совместно с Высшей школой корпоративного управления (ВШКУ РАНГХиГС) и Армянским государственным экономическим университетом объявляет набор слушателей на двухдневный интенсивный семинар на тему «Европейский менеджмент: система эффективного управления организацией на основе делегирования полномочий и ответственности».`,
                list: [
                    `В современном бизнесе ключевыми преимуществами в конкурентной борьбе становятся управленческая гибкость, умение адаптироваться к постоянно меняющейся среде, способность создавать и быстро внедрять инновационные решения во все ключевые процессы компании.`,
                    `В таких условиях административно- командный менеджмент больше не может быть эффективным и обеспечивать долгосрочный рост бизнеса. Проблемы «микроменеджмента» на всех уровнях особенно остро проявились во время пандемии, когда руководители потеряли возможность «ручного управления» и тотального контроля над сотрудниками.`,
                    `Система «мягкого» менеджмента с развитым чувством «порядка», основанная на делегировании полномочий и ответственности, разработана в Германии. Она эффективно трансформирует бизнес-процессы, повышает производительность труда, стимулирует раскрытие внутренних резервов сотрудников и способствует развитию инноваций.`,
                    `Эта модель управления лежит в основе менеджмента ведущих компаний Германии – Volkswagen, Audi, Bosch и др.`,
                ],
                btn: `Зарегистрироваться`

            },
            objectives: {
                title: `ЦЕЛИ И ЗАДАЧИ СЕМИНАРА`,
                list: [
                    {
                        icon: `icon1.svg`,
                        name: `ДИАГНОСТИКА СОСТОЯНИЯ<br/> МЕНЕДЖМЕНТА`,
                        text: [
                            `Диагностика состояния менеджмента в&nbsp;компании, выявление существующих проблем управления.`
                        ]
                    },

                    {
                        icon: `icon2.svg`,
                        name: `ПОСТРОЕНИЕ ЭФФЕКТИВНОЙ СИСТЕМЫ КОНТРОЛЯ`,
                        text: [
                            `Выстраивание эффективной системы контроля в&nbsp;организации`
                        ]
                    },

                    {
                        icon: `icon3.svg`,
                        name: `ВНЕДРЕНИЕ СИСТЕМЫ ДЕЛЕГИРОВАНИЯ ПОЛНОМОЧИЙ`,
                        text: [
                            `Внедрение основных принципов делегирования полномочий и&nbsp;ответственности в&nbsp;работу подразделений:`,
                            `<ul class="seminar-objective__text-list">
                                <li class="seminar-objective__text-point">Эффективное распределение полномочий и&nbsp;ответственности между руководителями и&nbsp;сотрудниками. Установление четких границ ответственности.</li>
                                <li class="seminar-objective__text-point">Устранение ключевых ошибок менеджмента: обратное делегирование, возврат полномочий, принятие «совместных» решений.</li>
                             </ul>
                            `,
                        ]
                    },

                    {
                        icon: `icon4.svg`,
                        name: `СИСТЕМА <br/> КОММУНИКАЦИИ`,
                        text: [
                            `Развитие системы коммуникаций между руководителями и&nbsp;сотрудниками: проведение совещаний, критических и&nbsp;поощрительных бесед, урегулирование конфликтов и&nbsp;рассмотрение жалоб.`
                        ]
                    },
                ]
            },
            plan: {
                title: `ТЕМАТИЧЕСКИЙ ПЛАН СЕМИНАРА`,
                list: [
                    {
                        name: `Изменение стиля менеджмента <br/> на&nbsp;основе делегирования <br/> полномочий <br/> и&nbsp;ответственности`,
                        text: [
                            `Основные понятия и&nbsp;принципы модели управления на&nbsp;основе делегирования полномочий и&nbsp;ответственности. Права и&nbsp;обязанности руководителя и&nbsp;сотрудника. Ответственность за&nbsp;действия и&nbsp;ответственность за&nbsp;управление. Обычные и&nbsp;особые случаи в&nbsp;управлении. Контроль как неделегируемая функция руководителя. Описание рабочего места и&nbsp;Общая инструкция по&nbsp;управлению как инструменты изменения менеджмента.`
                        ]
                    },
                    {
                        name: `Коммуникации <br/> в&nbsp;управленческой <br/> деятельности`,
                        text: [
                            `— Совещания в&nbsp;практике управления: консультационные и&nbsp;служебные совещания с&nbsp;сотрудниками.`,
                            `— Деловые беседы с&nbsp;сотрудниками: критика, поощрение, мотивация. Нематериальная мотивация и&nbsp;стимулирование инноваций как важный управленческий инструмент.`,
                            `— Анализ и&nbsp;урегулирование жалоб в&nbsp;практике управления.`
                        ]
                    },
                    {
                        name: `Контроль при&nbsp;управлении <br/> на&nbsp;основе делегирования <br/> полномочий`,
                        text: [
                            `Принципы, содержание и&nbsp;объем контроля при&nbsp;управлении на&nbsp;основе делегирования полномочий. Выборочный контроль и&nbsp;контроль результатов работы. Контроль профессиональной и&nbsp;управленческой деятельности. План проверок и&nbsp;отчет о&nbsp;проведении контроля. Обратная связь по&nbsp;итогам контроля.`,
                        ]
                    },
                ],
                btn: `Зарегистрироваться`
            },
            info: {
                title: `ЕВРОПЕЙСКИЙ МЕНЕДЖМЕНТ: СИСТЕМА ЭФФЕКТИВНОГО УПРАВЛЕНИЯ ОРГАНИЗАЦИЕЙ НА&nbsp;ОСНОВЕ ДЕЛЕГИРОВАНИЯ ПОЛНОМОЧИЙ И&nbsp;ОТВЕТСТВЕННОСТИ`,
                subtitle: `ДЛЯ КОГО СЕМИНАР?`,
                desc: `Семинар предназначен для руководителей армянских предприятий, заместителей директоров, руководителей подразделений, управлений и&nbsp;отделов.`,
                list: [
                    {
                        icon: `calendar.svg`,
                        name: `ДАТА И ВРЕМЯ <br/> ПРОВЕДЕНИЯ СЕМИНАРА`,
                        text: [
                            `<b>17 и 18 февраля 2023</b>`,
                            `<b>10:00–18:00</b>`
                        ]
                    },

                    {
                        icon: `point.svg`,
                        name: `МЕСТО ПРОВЕДЕНИЯ <br/> СЕМИНАРА`,
                        text: [
                            `Главный корпус Армянского Государственного Экономического Университета`,
                            `<b>Ереван, ул. Микаэла Налбандяна, 128</b>`
                        ]
                    },
                    {
                        icon: `tooltip.svg`,
                        name: `ОСОБЫЕ УСЛОВИЯ `,
                        text: [
                            `Семинар будет проходить <br/> на русском языке`,
                            `Слушатели получат авторские <br/> материалы по теме семинара`
                        ]
                    }
                ],
                disclaimer: `Стоимость участия в семинаре <span><strong>145,000 драм</strong></span> на человека. <br/>Оплата осуществляется безналичным переводом на основании договора.`
            },
            registration: {
                placeholders: {
                    surname: 'Фамилия *',
                    name: 'Имя *',
                    email: 'Электронный адрес *',
                    phone: 'Телефон',
                    company: 'Название компании',
                    position: 'Должность',
                },

                messages: {
                    empty: 'Поле обязательно для заполнения',
                    email: 'E-mail должен быть вида example@domain.com',
                    phone: 'Допускаются только + и цифры. Длина номера должна быть от 9 до 15 символов.'
                },

                result: {
                    success: `Спасибо! <br/> Ваше сообщение отправлено.`,
                    error: `Что-то пошло не так, <br/> попробуйте снова.`
                },

                checkbox: 'Я даю согласие на&nbsp;обработку моих персональных данных и&nbsp;соглашаюсь с&nbsp;политикой конфиденциальности',
                btn: `Зарегистрироваться`,
                curator: `КУРАТОР ПРОГРАММЫ: <b>ДИАНА АРМАГАНЯН</b>  +374 99 342626 | education@csda.am`
            },
            partners: {
                list: [
                    {
                        img: `partner1.svg`
                    },

                    {
                        img: `partner2.svg`
                    },

                    {
                        img: `partner3.svg`
                    },

                    {
                        img: `partner4.svg`
                    },

                    {
                        img: `partner5.svg`
                    },

                ]
            }
        },

        seminar2: {
            about: {
                title: 'ЭФФЕКТИВНАЯ СИСТЕМА УПРАВЛЕНИЯ ПЕРСОНАЛОМ КОМПАНИИ',
                subtitle: 'ДВУХДНЕВНЫЙ СЕМИНАР ДЛЯ РУКОВОДИТЕЛЕЙ АРМЯНСКИХ ПРЕДПРИЯТИЙ',
                date: '17-18 МАРТА 2023 ГОДА',
                desc: 'Национальный центр компетенций Армении, который организован фондом «Центр стратегического развития республики Армения» в рамках программы повышения производительности труда армянской экономики, совместно с&nbsp;Высшей школой корпоративного управления (ВШКУ РАНГХиГС) и Армянским государственным экономическим университетом объявляет набор слушателей на двухдневный интенсивный семинар на тему «ЭФФЕКТИВНАЯ СИСТЕМА УПРАВЛЕНИЯ ПЕРСОНАЛОМ КОМПАНИИ».',
                list: [
                    'Конкурентными преимуществами современного бизнеса становятся управленческая гибкость, умение адаптироваться к постоянно меняющейся среде, способность создавать и быстро внедрять инновационные решения.',
                    'Ключевую роль для достижения этих целей играют сотрудники, которые являются ценным капиталом организации.',
                    'Система эффективного управления сотрудниками, основанная на делегировании полномочий и ответственности, была разработана и активно используется в Германии. Она сочетает гибкость и управленческий порядок, трансформирует бизнес процессы, повышает производительность труда, стимулирует раскрытие внутренних резервов сотрудников и способствует развитию иноваций. Этот подход лежит в основе менеджмента ведущих европейских компаний.'
                ],
                btn: `Зарегистрироваться`
            },
            objectives: {
                title: `ЦЕЛИ И ЗАДАЧИ СЕМИНАРА`,
                list: [
                    'ВЫЯВЛЕНИЕ КЛЮЧЕВЫХ ПРОБЛЕМ УПРАВЛЕНИЯ СОТРУДНИКАМИ',
                    'ПОВЫШЕНИЕ ВОВЛЕЧЕННОСТИ СОТРУДНИКОВ ЗА&nbsp;СЧЕТ ТЕХНИК КОММУНИКАЦИИ И&nbsp;ИНСТРУМЕНТОВ НЕМАТЕРИАЛЬНОЙ МОТИВАЦИИ',
                    'ПОСТРОЕНИЕ ЭФФЕКТИВНОЙ СИСТЕМЫ ДЕЛЕГИРОВАНИЯ ПОЛНОМОЧИЙ, С&nbsp;УЧЕТОМ ЦЕЛЕЙ ОРГАНИЗАЦИИ И&nbsp;КОМПЕТЕНЦИЙ СОТРУДНИКОВ',
                    'УЛУЧШЕНИЕ АТМОСФЕРЫ В КОЛЛЕКТИВЕ И&nbsp;УСИЛЕНИЕ КОЛЛЕГИАЛЬНОГО СОТРУДНИЧЕСТВА',
                    'ПОВЫШЕНИЕ УРОВНЯ САМОСТОЯТЕЛЬНОСТИ СОТРУДНИКОВ ПРИ&nbsp;РЕШЕНИИ ЗАДАЧ',
                    'РАЗВИТИЕ НАВЫКОВ КОМАНДНОЙ РАБОТЫ СОТРУДНИКОВ',
                    'РОСТ ИНИЦИАТИВНОСТИ СОТРУДНИКОВ, РАЗВИТИЕ ИННОВАЦИЙ «СНИЗУ»',
                    'ВЫСВОБОЖДЕНИЕ ВРЕМЕНИ РУКОВОДИТЕЛЕЙ ОТ&nbsp;ОПЕРАЦИОННОЙ ДЕЯТЕЛЬНОСТИ ДЛЯ&nbsp;РЕШЕНИЯ СТРАТЕГИЧЕСКИХ ЗАДАЧ',
                    'СОКРАЩЕНИЕ ВРЕМЕНИ НА&nbsp;КОНТРОЛЬ СОТРУДНИКОВ(ПРИ&nbsp;ОДНОВРЕМЕННОМ СНИЖЕНИИ РИСКОВ ДЕЛЕГИРОВАНИЯ)',
                    'РОСТ ПРОИЗВОДИТЕЛЬНОСТИ ТРУДА И КОНКУРЕНТОСПОСОБНОСТИ ОРГАНИЗАЦИИ',
                ]
            },
            plan: {
                title: `ТЕМАТИЧЕСКИЙ ПЛАН СЕМИНАРА`,
                list: [
                    'Основы европейского менеджмента. Принципы и&nbsp;инструменты построения эффективной системы делегирования полномочий и&nbsp;ответственности',
                    'Коммуникации в управленческой деятельности: совещания, критические и&nbsp;поощрительные беседы, урегулирование жалоб, инструменты нематериальной мотивации',
                    'Информирование и&nbsp;коллегиальное сотрудничество в&nbsp;организации',
                    'Контроль при&nbsp;управлении на&nbsp;основе делегирования полномочий'
                ],
                btn: `Зарегистрироваться`
            },
            info: {
                title: 'ДЛЯ КОГО СЕМИНАР?',
                desc: 'Семинар предназначен для&nbsp;руководителей армянских предприятий, заместителей директоров, руководителей подразделений, управлений и&nbsp;отделов.',
                list: [
                    {
                        icon: `calendar.svg`,
                        name: `ДАТА И ВРЕМЯ <br/> ПРОВЕДЕНИЯ СЕМИНАРА`,
                        text: [
                            `<b>17 и 18 марта 2023</b>`,
                            `<b>10:00–18:00</b>`
                        ]
                    },
                    {
                        icon: `point.svg`,
                        name: `МЕСТО ПРОВЕДЕНИЯ <br/> СЕМИНАРА`,
                        text: [
                            `Главный корпус Армянского Государственного Экономического Университета`,
                            `<b>Ереван, ул. Микаэла Налбандяна, 128</b>`
                        ]
                    },
                    {
                        icon: `tooltip.svg`,
                        name: `ОСОБЫЕ УСЛОВИЯ `,
                        text: [
                            `Семинар будет проходить <br/> на&nbsp;русском языке`,
                            `Слушатели получат авторские <br/> материалы по&nbsp;теме семинара`
                        ]
                    }
                ],
                disclaimer: `Стоимость участия в&nbsp;семинаре <span><strong>80,000 драм</strong></span> на&nbsp;человека. <br/>Оплата осуществляется безналичным переводом на&nbsp;основании договора.`,
                btn: `Зарегистрироваться`
            },

            schedule: {
                title: 'РАСПИСАНИЕ',
                list: [
                    {
                        date: '17 марта, день первый',
                        content: [
                            {
                                time: '10.00 – 11.30',
                                text: [
                                    `Введение в&nbsp;систему управления на&nbsp;основе делегирования полномочий и&nbsp;ответственности. Преимущества европейского подхода к&nbsp;управлению сотрудниками и&nbsp;возможности его адаптации в&nbsp;компаниях Армении.`,
                                    `Диагностика управленческих проблем в&nbsp;организации.`,
                                ]
                            },

                            {
                                time: '12.00 – 13.30',
                                text: [
                                    `Основные понятия и&nbsp;принципы модели управления на&nbsp;основе делегирования полномочий и&nbsp;ответственности. Делегирование как инструмент повышения вовлеченности сотрудников.`,
                                    `Разбор практических ситуаций участников семинара, ответы на&nbsp;вопросы.`,
                                ]
                            },

                            {
                                time: '14.30 – 16.00',
                                text: [
                                    `Обычные и&nbsp;особые случаи в&nbsp;управлении. Контроль как инструмент минимизации рисков делегирования полномочий. Основы информирования и&nbsp;коллегиального сотрудничества в&nbsp;организации.`,
                                    `Кейс 1: «Начальник коммерческого отдела в&nbsp;затруднительном положении».`,
                                    `Кейс 2: «Несогласованные действия могут навредить».`,
                                ]
                            },

                            {
                                time: '16.15 – 18.00',
                                text: [
                                    `Описание рабочего места и&nbsp;Общая инструкция по&nbsp;управлению как инструменты изменения менеджмента. Ошибки руководителя при&nbsp;делегировании полномочий.`,
                                    `<span>Тренинг: ошибки делегирования полномочий сотрудникам.</span>`,
                                    `Кейс 3: «Начальник отдела маркетинга не&nbsp;желает нести ответственность».`,
                                    `<span>Дискуссия: преимущества и&nbsp;риски делегирования полномочий для руководителя, сотрудника и&nbsp;организации.</span>`,
                                    `Ответы на вопросы участников.`
                                ]
                            },
                        ]
                    },
                    {
                        date: '18 марта, день второй',
                        content: [
                            {
                                time: '10.00 – 11.30 ',
                                text: [
                                    `Коммуникации руководителя с&nbsp;сотрудниками. Особенности и&nbsp;правила коммуникации при&nbsp;управлении на&nbsp;основе делегирования полномочий. Совещания в&nbsp;практике управления: консультационные и&nbsp;служебные совещания.`,
                                    `<span>Дискуссия и&nbsp;разбор практических ситуаций участников: «Ошибки проведения совещаний и&nbsp;методы повышения эффективности совещаний в&nbsp;организации».</span>`,
                                    `Кейс 4: «Совещания в практике управления».`,
                                ]
                            },

                            {
                                time: '12.00 – 13.30 ',
                                text: [
                                    `<span>Тренинг: проведение консультационного совещания с&nbsp;сотрудниками. Работа в&nbsp;группах, презентация итогов.</span>`,
                                ]
                            },

                            {
                                time: '14.30 – 16.00',
                                text: [
                                    `Деловые беседы с&nbsp;сотрудниками: критика и&nbsp;поощрение. Нематериальная мотивация и&nbsp;стимулирование инноваций как важный управленческий инструмент. Техника урегулирования жалоб.`,
                                    `<span>Тренинг: проведение критической беседы с&nbsp;сотрудником.</span>`,
                                    `<span>Тренинг: проведение поощрительной беседы с&nbsp;сотрудником.</span>`,
                                ]
                            },

                            {
                                time: '16.15 – 18.00 ',
                                text: [
                                    `Контроль при управлении на&nbsp;основе делегирования полномочий. Принципы, содержание и&nbsp;объем контроля. Выборочный контроль и&nbsp;контроль результатов работы. Контроль профессиональной и&nbsp;управленческой деятельности. Методы и&nbsp;формы контроля. План проверок и&nbsp;отчет о&nbsp;проведении контроля.`,
                                    `<span>Тренинг (работа в группах): составление плана контроля профессиональной и&nbsp;управленческой деятельности сотрудника.</span>`,
                                    `Кейс 4: «Нелегко быть директором».`,
                                    `Подведение итогов семинара, ответы на&nbsp;вопросы участников.`,
                                ]
                            },
                        ]
                    },
                ],
                btn: `Зарегистрироваться`
            },

            registration: {
                placeholders: {
                    surname: 'Фамилия *',
                    name: 'Имя *',
                    email: 'Электронный адрес *',
                    phone: 'Телефон',
                    company: 'Название компании',
                    position: 'Должность',
                },

                messages: {
                    empty: 'Поле обязательно для заполнения',
                    email: 'E-mail должен быть вида example@domain.com',
                    phone: 'Допускаются только + и цифры. Длина номера должна быть от 9 до 15 символов.'
                },

                result: {
                    success: `Спасибо! <br/> Ваше сообщение отправлено.`,
                    error: `Что-то пошло не так, <br/> попробуйте снова.`
                },

                checkbox: 'Я даю согласие на&nbsp;обработку моих персональных данных и&nbsp;соглашаюсь с&nbsp;политикой конфиденциальности',
                btn: `Зарегистрироваться`,
                curator: `КУРАТОР ПРОГРАММЫ: <b>ДИАНА АРМАГАНЯН</b>  +374 99 342626 | education@csda.am`
            },
            partners: {
                list: [
                    {
                        img: `partner1.svg`
                    },

                    {
                        img: `partner2.svg`
                    },

                    {
                        img: `partner3.svg`
                    },

                    {
                        img: `partner4.svg`
                    },

                    {
                        img: `partner5.svg`
                    },

                ]
            }
        },

        exhibitions: {
            title: `КАЛЕНДАРЬ ВЫСТАВОК АРМЕНИИ НА 2023 ГОД`,
            desc: `Ежегодно в Армении проводятся крупные международные выставочно-ярмарочные мероприятия, в которых принимают участие компании со всего мира. Выставки предоставляют возможность расширить клиентскую базу и повысить продажи, найти способы оптимизации производственных процессов, повысить конкурентоспособность, обеспечить позитивное восприятие бренда или компании, увеличить репутационный капитал.`,
            list: [
                {
                    date: `10-12 марта 2023`,
                    name: `DIGITEC`,
                    link: `https://digitec.am`
                },

                {
                    date: `17-19 марта 2023`,
                    name: `КАВКАЗ: СТРОИТЕЛЬСТВО И&nbsp;РЕМОНТ`,
                    link: `https://expo.am/ru/exhibition/kavkaz-stroitelstvo-i-remont-expo`
                },

                {
                    date: `5-7 апреля 2023`,
                    name: `ОБРАЗОВАНИЕ И&nbsp;КАРЬЕРА`,
                    link: `https://expo.am/ru/exhibition/obrazovanie-i-karera-expo`
                },

                {
                    date: `19-21 мая 2023`,
                    name: `СТРОИТЕЛЬСТВО И&nbsp;ИНТЕРЬЕР`,
                    link: `https://www.promexpo.am/exhibition.php?ex=cNi`
                },

                {
                    date: `19-21 мая 2023`,
                    name: `EXPO FOOD & DRINKS`,
                    link: `https://expo.am/ru/exhibition/expo-food-drinks`
                },

                {
                    date: `2-4 июня 2023`,
                    name: `ЛЕДИ EXPO`,
                    link: `https://expo.am/ru/exhibition/ledi-expo`
                },

                {
                    date: `2-4 июня 2023`,
                    name: `МИР ДЕТСТВА`,
                    link: `https://expo.am/ru/exhibition/mir-detstva-expo`
                },

                {
                    date: `6-8 сентября 2023`,
                    name: `ПАНАРМЕНИАН`,
                    link: `https://www.promexpo.am/index.php`
                },

                {
                    date: `15-17 сентября 2023`,
                    name: `АРМЕНИЯ EXPO`,
                    link: `https://expo.am/ru/exhibition/armeniya-expo`
                },

                {
                    date: `20-22 октября 2023`,
                    name: `АРМПРОД EXPO 2023`,
                    link: `https://expo.am/armprod-expo`
                },

                {
                    date: `3-5 ноября 2023`,
                    name: `ЗДРАВООХРАНЕНИЕ И&nbsp;ФАРМАЦИЯ EXPO 2023`,
                    link: `https://expo.am/ru/exhibition/zdravoohranenie-i-farmaciya-expo`
                },
            ]
        },

        contacts: {
            title: 'Контакты',
            fond: {
                head: 'Фонд “Центр стратегического развития Республики Армения”',
                text: [
                    'info@csda.am',
                    '+37400000000',
                    'Мгер Мкртчян 5 Ереван, 0010 Армения'
                ]
            },
            vavt: {
                head: 'Разработка портала:',
                text: [
                    {
                        content: 'Институт развития интеграционных процессов',
                        link: 'http://www.vavt.ru/science/site/LSP6ED90B'
                    },
                    {
                        content: `Центр анализа данных Всероссийской академии внешней торговли Министерства экономического развития Российской Федерации`,
                        link: 'http://www.vavt.ru/science/site/cda'
                    }
                ]
            }
        }
    },

    arm: {
        main: {
            head: {
                title: 'ՀԱՅԱՍՏԱՆԻ ՌԱԶՄԱՎԱՐԱԿԱՆ ԶԱՐԳԱՑՄԱՆ ԿԵՆՏՐՈՆ',
                subtitle: `«Հայաստանի Հանրապետության Ռազմավարական Զարգացման Կենտրոն» հիմնադրամը ստեղծվել է պետական կառավարման և ինստիտուցիոնալ զարգացման լավագույն համաշխարհային փորձի ներդրման միջոցով Հայաստանի Հանրապետության տնտեսական աճին նպաստելու նպատակով։`
            },

            history: {
                title: 'ՀԻՄՆԱԴՐԱՄԻ ՍՏԵՂԾՄԱՆ ՊԱՏՄՈՒԹՅՈՒՆ',
                text1: `2022 թվականի սեպտեմբերին ստեղծվել է «Հայաստանի Հանրապետության Ռազմավարական Զարգացման Կենտրոն» հիմնադրամը, որի հիմնադիրներն են՝ ռուսական կողմից՝ <a href="https://www.vavt.ru/" target="_blank">«Ռուստստանի Դաշնության Տնտեսագիտական Զարգացման նախարարությանը կից Համառուսական Արտաքին Առևտրի Ակադեմիա» դաշնային բարձրագույն կրթության ուսումնական հաստատությունը</a>, իսկ հայկական կողմից՝ <a href="https://dica.am/ru/" target="_blank">Հայաստանի Զարգացման և Ներդրումների Կորպորացիան</a>։`,
                text2: `Հիմնադրամը ստեղծվել է 2022 թվականի հունիսին Սանկտ Պետերբուրգի միջազգային տնտեսական ֆորումի շրջանակներում <a href="https://www.mineconomy.am/ru" target="_blank">Հայաստանի Հանրապետության էկոնոմիկայի նախարարության</a> և <a href="https://www.economy.gov.ru/" target="_blank">ՌԴ տնտեսական զարգացման նախարարության</a> միջև ստորագրված հուշագրի հիման վրա։`,
            },

            goals: {
                title: 'ՀԻՄՆԱԴՐԱՄԻ ՆՊԱՏԱԿՆԵՐԸ',
                list: [
                    `Աջակցություն Հայաստանի Հանրապետության տնտեսական և ինստիտուցիոնալ զարգացմանը`,
                    `Հայաստանի Հանրապետության, Ռուսաստանի Դաշնության և ԵԱՏՄ այլ գործընկերների միջև առևտրատնտեսական համագործակցության զարգացմանը նպաստելը`,
                    `Եվրասիական ինտեգրումից եկամտի աճի գրանցում`
                ]
            },

            tasks: {
                title: 'ՀԻՄՆԱԴՐԱՄԻ ԽՆԴԻՐՆԵՐԸ',
                list: [
                    `Պետական կառավարման և ինստիտուցիոնալ զարգացման ռուսական և համաշխարհային լավագույն փորձի ներդրում`,
                    `Հայաստանի Հանրապետության երկարաժամկետ ոլորտային ռազմավարությունների և ծրագրերի մշակում`,
                    `Հայաստանի Հանրապետության և ԵԱՏՄ զարգացման առաջնահերթություններին համապատասխանող ներդրումային նախագծերի մշակում`,
                    `Ռուսաստանի և ԵԱՏՄ-ի գործընկերների հետ արդյունաբերական համագործակցության զարգացում`,
                    `Հայաստանի Հանրապետության և Ռուսաստանի Դաշնության միջև առևտրատնտեսական համագործակցության զարգացմանը խոչընդոտների մոնիտորինգ, դրանց հաղթահարման առաջարկների մշակում։`,
                ]
            },

            documents: {
                title: 'ՓԱՍՏԱԹՂԹԵՐ',
                list: [
                    {
                        name: 'Հիմնադրամի կանոնադրությունը',
                        link: 'Устав_ЦСРА.pdf',
                    },
                    {
                        name: 'Պետական գրանցման վկայական',
                        link: 'Свидетельство_о_регистрации_ЦСРА.pdf',
                    },
                ]
            },

            projects: {
                title: 'ՆԱԽԱԳԾԵՐ',
                list: [
                    {
                        img: 'project1.png',
                        name: 'Հանրային-մասնավոր համագործակցության մեխանիզմների կիրառմամբ նոր դպրոցների ստեղծման Հայաստանի Հանրապետության պետական ծրագրի մշակում',
                        description: `Կրթության ոլորտում հանրային-մասնավոր համագործակցության զարգացման համաշխարհային և ռուսական լավագույն փորձի վերլուծություն: Հայաստանի Հանրապետության կրթության ոլորտում հանրային-մասնավոր համագործակցության խոչընդոտների բացահայտում. Հայաստանում հայտնաբերված խոչընդոտները հաղթահարելու համար կիրառելի համաշխարհային և ռուսական լավագույն փորձի տարածման վերաբերյալ առաջարկությունների մշակում։ Հանրային-մասնավոր համագործակցության մեխանիզմների կիրառմամբ նոր դպրոցների ստեղծման Հայաստանի Հանրապետության պետական ծրագրի մշակում.`
                    },

                    {
                        img: 'project2.png',
                        name: 'Հայաստանի Հանրապետության զարգացման միասնական ինստիտուտի ձևավորման իրագործելիության և առաջարկների մշակում',
                        description: 'Արդյունաբերական համագործակցության և արտահանման խթանման և աջակցության զարգացման ինստիտուտների ստեղծման և գործունեության համաշխարհային փորձի վերլուծություն՝ Հայաստանի Հանրապետությունում նման զարգացման ինստիտուտի բարելավման և, անհրաժեշտության դեպքում, ստեղծման առաջարկությունների մշակման նպատակով:'
                    },

                    {
                        img: 'project3.png',
                        name: 'Արդյունաբերական պարկերի կառուցման Հայաստանի Հանրապետության պետական ծրագրի նախագծի կազմում',
                        description: 'Արդյունաբերության առանձին տեսակների համար նոր արդյունաբերական պարկերի կառուցման հարցում երկրի կարիքների գնահատում. Ոլորտների բացահայտում, որոնց համար արդյունաբերական պարկերի ձևավորումը կապահովի ամենաշատ արդյունավետությունը (ՀՀ տնտեսության օրինակով) և արդյունաբերական պարկերի կառուցման պետական ծրագրի հայեցակարգի մշակում։ ԵԱՏՄ շրջանակներում այս ոլորտում համագործակցության հեռանկարների գնահատում և վերլուծություն։'
                    },

                    {
                        img: 'project4.png',
                        name: 'Ներդրումային բարենպաստ միջավայրի զարգացման նպաստում',
                        description: 'Երկրի ներդրումային միջավայրի վերլուծություն, ներառյալ կոնցեսիոն օրենսդրությունը, հանրային-մասնավոր համագործակցության կարգավորումը և դրա բարելավմանն ուղղված առաջարկների մշակումը: Առաջարկվող փոփոխությունների տնտեսական հետևանքների գնահատում։'
                    },
                ]
            },

            partners: {
                title: 'ԳՈՐԾԸՆԿԵՐՆԵՐ',

                list: [
                    {
                        img: 'logo1.png',
                        desc: 'Հայաստանի ազգային պոլիտեխնիկական համալսարան'
                    },

                    {
                        img: 'logo2.png',
                        desc: 'Էնթերփրայզ Արմենիա'
                    },

                    {
                        img: 'logo4.png',
                        desc: 'Մոսկովյան Մշակութագործարարական Կենտրոն «Դոմ Մոսկվի»'
                    },

                    {
                        img: 'logo6.png',
                        desc: 'Հայաստանի Պետական Տնտեսագիտական Համալսարան'
                    },

                    {
                        img: 'logo8.png',
                        desc: 'Հայ-Ռուսական Համալսարան'
                    },

                    {
                        img: 'logo9.png',
                        desc: 'Երևանի Պետական Համալսարան'
                    },

                ],

                btn: 'Դառնալ գործընկեր',


                form: {
                    placeholders: {
                        fio: 'ФИО *',
                        position: 'Должность',
                        company: 'Организация *',
                        country: 'Страна регистрации *',
                        partnership: 'Сферы сотрудничества *',
                        phone: 'Номер телефона',
                        email: 'Электронная почта *',
                    },

                    messages: {
                        empty: 'Поле обязательно для заполнения',
                        email: 'E-mail должен быть вида example@domain.com',
                        phone: 'Допускаются только + и цифры. Длина номера должна быть от 9 до 15 символов.'
                    },

                    result: {
                        success: `Спасибо! <br/> Ваше сообщение отправлено.`,
                        error: `Что-то пошло не так, <br/> попробуйте снова.`
                    },

                    checkbox: 'Я даю согласие на&nbsp;обработку моих персональных данных и&nbsp;соглашаюсь с&nbsp;политикой конфиденциальности'
                },
            },
        },

        support: {
            title: 'Ձեռնարկատերերի աջակցության ռեսուրսներ',

            topics: {
                msp: {
                    img: 'logo1.png',
                    list: [
                        {
                            title: 'Բիզնեսի գրանցում',
                            links: [
                                {
                                    name: 'Բիզնեսի գրանցումը Ռուսաստանում',
                                    link: 'https://xn--l1agf.xn--p1ai/services/businessRegistration/'
                                },

                                {
                                    name: 'Կազմակերպական-իրավական ձևի ընտրություն',
                                    link: 'https://xn--l1agf.xn--p1ai/services/opf/'
                                },

                                {
                                    name: 'Էլեկտրոնային ստորագրության ձեռքբերում',
                                    link: 'https://xn--l1agf.xn--p1ai/services/ukep/'
                                },
                            ]
                        },

                        {
                            title: 'Ձեռնարկատերերի աջակցության միջոցառումներ',
                            links: [
                                {
                                    name: 'Ռուսաստանում ՓՄՁ աջակցության միջոցառումներ, որոնք հասանելի են ԱՊՀ երկրների ձեռնարկությունների համար',
                                    link: 'https://xn--l1agf.xn--p1ai/services/support/'
                                },
                            ]
                        },

                        {
                            title: 'Պետական գնումներ',
                            links: [
                                {
                                    name: 'Ռուսաստանում ՓՄՁ-ների մասնակցությունը պետական գնումներին (մինչև 500 հազար ռուբլի)',
                                    link: 'https://xn--l1agf.xn--p1ai/services/purchase_access/'
                                },
                            ]
                        },

                        {
                            title: 'Պայմանադիր կողմի (կոնտրագենտ) ստուգում',
                            links: [
                                {
                                    name: 'Պայմանադիր կողմի (կոնտրագենտ) ստուգում',
                                    link: 'https://xn--l1agf.xn--p1ai/services/counterparty/ '
                                },
                            ]
                        },

                        {
                            title: 'Պայմանադիր կողմի (կոնտրագենտի) որոնում Ռուսաստանում',
                            links: [
                                {
                                    name: 'Վաճառքի համար ապրանքներ առաջարկող ռուսական ընկերությունների ռեեստր',
                                    link: 'https://xn--l1agf.xn--p1ai/services/development/'
                                },

                                {
                                    name: 'Ապրանքների մատակարարներ փնտրող ռուսական ընկերությունների ռեեստր',
                                    link: 'https://xn--l1agf.xn--p1ai/services/development/'
                                },
                            ]
                        },
                    ]
                },

                rec: {
                    img: 'logo2.png',
                    list: [
                        {
                            title: 'Ապրանքների ներմուծում-արտահանում',
                            links: [
                                {
                                    name: 'Ռուսաստանից ապրանքների ներմուծման, ինչպես նաև ռուսական շուկա ապրանքների արտահանման պարագայում պարտադիր պահանջների վերաբերյալ պարզաբանումների ստացում',
                                    link: 'https://cutt.ly/fMe683I'
                                },

                                {
                                    name: 'Արտահանման պայմանագրի նմուշ` Ռուսաստան ապրանքների մատակարարման համար',
                                    link: 'https://cutt.ly/sMe5Hm1'
                                },


                                {
                                    name: 'Ռուսաստանի Դաշնության տարբեր շրջանների շուկաներում ապրանքների լոգիստիկա',
                                    link: 'https://cutt.ly/JMe5CQZ'
                                },
                            ]
                        },
                    ]
                },

                // mpt: {
                //     img: 'logo3.png',
                //     list: [
                //         {
                //             title: 'Заявка на проект в России',
                //             links: [
                //                 {
                //                     name: 'Оставить заявку на экспортный или инвестиционный проект в России',
                //                     link: 'https://arm.minpromtorg.gov.ru/'
                //                 },
                //             ]
                //         },
                //     ]
                // }
            },

            registration: {
                title: `Ռուսաստանում կամ Հայաստանում բիզնեսով զբաղվելիս խոչընդոտների դեպքում դիմեք Հայաստանի Հանրապետության ռազմավարական զարգացման կենտրոն`,
                categories: {
                    desc: 'Հայտ ներկայացնել որպես',
                    jPerson: 'Իրավաբանական անձ',
                    phPerson: 'Ֆիզիկական անձ'
                },

                placeholdersMain: {
                    surname: 'Ազգանուն *',
                    name: 'Անուն *',
                    patronym: 'Հայրանուն',
                    email: 'Էլեկտրոնային փոստ *',
                    phone: 'Հեռախոսահամար',
                    company: 'Կազմակերպության անվանում *',
                    text: 'Հաղորդագրություն *'
                },
                types: {
                    placeholder: 'Բողոքարկման տեսակի ընտրություն *',
                    options: [
                        'Առաջարկ',
                        'Խնդիր'
                    ],
                },


                placeholders: {
                    fio: 'ФИО *',
                    company: 'Наименование компании *',
                    position: 'Должность',
                    country: 'Страна регистрации *',
                    phone: 'Номер телефона',
                    email: 'Электронная почта *',
                    text: 'Вопрос *'
                },

                messages: {
                    empty: 'Պարտադիր դաշտ:',
                    email: 'Էլ. փոստը պետք է նման լինի example@domain.com-ին:',
                    phone: 'Թույլատրվում են միայն + և թվեր:'
                },

                result: {
                    success: `Շնորհակալություն! <br/> Ձեր հաղորդագրությունն ուղարկված է`,
                    error: `Սխալ առաջացավ, <br/> խնդրում ենք կրկին փորձեք:`
                },

                checkbox: 'Ես համաձայն եմ անձնական տվյալների մշակմանը և գաղտնիության քաղաքականությանը'
            },
        },

        experts: {
            title: 'Փորձագետներ',
            director: {
                fio: 'Կարեն Ավագյան',
                desc: `«Հայաստանի Հանրապետության <br/> Ռազմավարական Զարգացման Կենտրոն» <br/> Հիմնադրամի տնօրեն`,
                text: [
                    `Ծնվել է Երևանում 1979թ.։`,
                    `2002 թվականին ավարտել է Ռուսաստանի Կ. Է. Ցիոլկովսկու անվան պետական տեխնոլոգիական համալսարանի տնտեսագիտական ֆակուլտետը՝ տնտեսագիտություն և կառավարում մասնագիտությամբ:`,
                    `2018 թվականին Սկոլկովոյի բիզնես դպրոցի շրջանավարտ։`,
                    `Մինչև 2004 թվականը զբաղեցրել է Ռուսաստանում՝ Մոսկվայում, Daewoo Heavy Industries-ի պաշտոնական ներկայացուցչության արտաքին տնտեսական կապերի բաժնի ղեկավարի տեղակալի պաշտոնը։`,
                    `Մինչեւ 2013 թվականը զբաղեցրել է Մոսկվայի Hyundai Mobis CIS-ի կոմերցիոն տնօրենի պաշտոնը։`,
                    `Մինչև 2019 թվականը Մոսկվայում տեղեկատվական տեխնոլոգիաների և մեքենայական ուսուցման ոլորտում նախագծերի համահիմնադիր։`,
                    `Մինչև 2022 թվականը Երևանում խորհրդատվություն է տվել միջազգային ընկերություններին մեքենաշինության և արդյունաբերության ոլորտում։`,
                    `2022 թվականի սեպտեմբերից՝ «Հայաստանի Հանրապետության Ռազմավարական Զարգացման Կենտրոն» հիմնադրամի տնօրեն։`,
                ]
            },
            list: [
                {
                    img: 'photo1.png',
                    name: 'Պարգև Տոնապետյան',
                    description: 'Հայաստանի Ազգային Պոլիտեխնիկական Համալսարանի Տնտեսագիտության և փոխադրումների կազմակերպման ամբիոնի պրոֆեսոր'
                },

                {
                    img: 'photo2.png',
                    name: 'Հենրիկ Քոչինյան',
                    description: 'Հայաստանի Ազգային Պոլիտեխնիկական Համալսարանի Տնտեսագիտության և փոխադրումների կազմակերպման ամբիոնի վարիչ'
                },

                {
                    img: 'photo3.png',
                    name: 'Գրիգոր Նազարյան',
                    description: 'Հայաստանի Պետական Տնտեսագիտական Համալսարանի Տնտեսության կարգավորման և միջազգային տնտեսական հարաբերությունների ֆակուլտետի դեկան'
                },

                {
                    img: 'photo4.png',
                    name: 'Սերգեյ Քալենջյան',
                    description: 'տնտեսագիտական գիտությունների դոկտոր, DBA, պրոֆեսոր Ռուսաստանի վաստակավոր տնտեսագետ։ ՌԱՏևՊԿԱ-ի Տնտեսագիտության բարձրագույն դպրոցի հիմնադիր և դեկան: Եվրոպական համալսարանի (Շվեյցարիա) և Միջազգային կառավարման եվրոպական ինստիտուտի (Ֆրանսիա) պատվավոր դոկտոր։ Բարեկամության շքանշանի ասպետ։'
                },

                {
                    img: 'photo5.png',
                    name: 'Օլգա Գումիլյովսկայա',
                    description: 'DBA, ԿԿԲԴ ՌԱՏևՊԿԱ միջազգային զարգացման և հավատարմագրման փոխդեկան։ ԿԿԲԴ ՌԱՏևՊԿԱ կորպորատիվ կառավարման ամբիոնի դասախոս, բիզնես մարզիչ։'
                },

                {
                    img: 'photo8.png',
                    name: 'Դիանա Արմաղանյա',
                    description: 'Հայաստանի ռազմավարական զարգացման կենտրոնի փորձագետ, Մարքեթոլոգ'
                },

                {
                    img: 'photo9.png',
                    name: 'Մարիամ Ոսկանյան',
                    description: 'տնտեսագիտական գիտությունների դոկտոր, դոցենտ, Հայ Ռուսական Համալսարանի տնտեսագիտության և ֆինանսների ամբիոնի վարիչ'
                },

            ]
        },

        faq: {
            title: 'FAQ Հայաստանի գործարարների համար Ռուսաստանի հետ բիզնես վարելու և ԱՏԳ -ի վերաբերյալ',
            list: [
                {
                    name: 'Ի՞նչ նորմատիվ պահանջներով պետք է առաջնորդվել  դեպի Ռուսաստան հայկական մրգային գինիների մատակարարման դեպքում։',
                    text: [
                        `Հայկական "մրգային գինիները" 2022 թվականի հունվարի 1-ից կարող են շրջանառվել Ռուսաստանի Դաշնության շուկայում , համաձայն 22.12.1995 թվականի <a href="https://base.garant.ru/10105489/" target="_blank">թիվ 171 Դաշնային օրենքի պահանջներին</a>՝ "գինի" բառը բացառել  ապրանքի անվանումից ։`,
                        `2022 թվականի հունվարի 1-ից ուժի մեջ են մտել "Ռուսաստանի Դաշնությունում խաղողագործության և գինեգործության մասին" 27.12.2019 թ. <a href="http://ivo.garant.ru/#/document/73355393/paragraph/2" target="_blank">թիվ 468  Դաշնային օրենքի դրույթները</a>, որոնց համաձայն՝ "գինի" բառի և դրանից ստացված բառերի օգտագործումը խաղողից բացի ,այլ մրգերի խմորումից ստացված արտադրանքի նկատմամբ կհամարվի կեղծիք (26 հոդված 4.1. կ.):`,
                        `Նման արտադրանքը կարող է անվանվել " Պտղային ալկոհոլային արտադրանք"։`,
                        `Միևնույն ժամանակ, անփոփոխ են մնացել արտադրանքի նկատմամբ պահանջները, որոնք սահմանված են ըստ  <a href="http://ivo.garant.ru/#/document/71680264/paragraph/1/doclist/2615/showentries/0/highlight/JTVCJTdCJTIybmVlZF9jb3JyZWN0aW9uJTIyJTNBZmFsc2UlMkMlMjJjb250ZXh0JTIyJTNBJTIyJTVDdTA0MTMlNUN1MDQxZSU1Q3UwNDIxJTVDdTA0MjIlMjAzMzgwNi0yMDE2JTIwJTVDdTA0MTIlNUN1MDQzOCU1Q3UwNDNkJTVDdTA0MzAlMjAlNUN1MDQ0NCU1Q3UwNDQwJTVDdTA0NDMlNUN1MDQzYSU1Q3UwNDQyJTVDdTA0M2UlNUN1MDQzMiU1Q3UwNDRiJTVDdTA0MzUlMjAlNUN1MDQ0MSU1Q3UwNDQyJTVDdTA0M2UlNUN1MDQzYiU1Q3UwNDNlJTVDdTA0MzIlNUN1MDQ0YiU1Q3UwNDM1JTIwJTVDdTA0MzglMjAlNUN1MDQzMiU1Q3UwNDM4JTVDdTA0M2QlNUN1MDQzZSU1Q3UwNDNjJTVDdTA0MzAlNUN1MDQ0MiU1Q3UwNDM1JTVDdTA0NDAlNUN1MDQzOCU1Q3UwNDMwJTVDdTA0M2IlNUN1MDQ0YiUyMCU1Q3UwNDQ0JTVDdTA0NDAlNUN1MDQ0MyU1Q3UwNDNhJTVDdTA0NDIlNUN1MDQzZSU1Q3UwNDMyJTVDdTA0NGIlNUN1MDQzNSUyMCU1Q3UwNDQxJTVDdTA0NDIlNUN1MDQzZSU1Q3UwNDNiJTVDdTA0M2UlNUN1MDQzMiU1Q3UwNDRiJTVDdTA0MzUuJTIwJTVDdTA0MWUlNUN1MDQzMSU1Q3UwNDQ5JTVDdTA0MzglNUN1MDQzNSUyMCU1Q3UwNDQyJTVDdTA0MzUlNUN1MDQ0NSU1Q3UwNDNkJTVDdTA0MzglNUN1MDQ0NyU1Q3UwNDM1JTVDdTA0NDElNUN1MDQzYSU1Q3UwNDM4JTVDdTA0MzUlMjAlNUN1MDQ0MyU1Q3UwNDQxJTVDdTA0M2IlNUN1MDQzZSU1Q3UwNDMyJTVDdTA0MzglNUN1MDQ0ZiUyMiU3RCU1RA==" target="_blank">ԳՕՍՏ 33806-2016</a> "Մրգային սեղանի գինիներ և գինեհումք մրգային սեղանի. Ընդհանուր տեխնիկական պայմանների"։`,
                    ],
                    category: 'ԱՏԳ. Ապրանքների ներմուծում Ռուսաստան։',
                    tags: [
                        'Ապրանքների հասանելիություն',
                        'Մրգեր',
                        'Գինի',
                    ]
                },

                {
                    name: `Ի՞նչ ժամկետներում է սահմանվել Հայաստանից մսի և կաթնամթերքի արտահանման արգելքը։`,
                    text: [
                        `Ռոսսելխոզնադզորի <a href="https://fsvps.gov.ru/sites/default/files/npa-files/2016/01/20/tu_armeniya_20._01_6347173307_fs-en-8_634_20_01_2016.pdf" target="_blank">2016 թվականի հունվարի 20-ի հրամանով</a> արգելվել է Հայաստանի Հանրապետության տարածքից մսի և կաթնամթերքի ներմուծումը Ռուսաստանի Դաշնություն։`,
                        `Ռուսաստանի Դաշնությունը 2016-ին ստացել է Կենդանիների առողջապահության համաշխարհային կազմակերպության (ԿԱՀԿ) պաշտոնական կարգավիճակ ՝ առանց պատվաստումների FMD-ից զերծ գոտի ունեցող երկիր:`,
                        `Կենդանի կենդանիների և անասնաբուծական արտադրանքի ներմուծումը պետք է իրականացվի ԿԱՀԿ օրենսգրքի դրույթներին համապատասխան: Այն երկրները, որոնք չունեն ԿԱՀԿ պաշտոնական կարգավիճակ ճանաչվում են որպես անբարենպաստ։`,
                        `Հայաստանը ներկայումս չունի ԿԱՀԿ կարգավիճակ և դիտարկվում է  որպես անբարենպաստ երկիր:`,
                        `Նման ճանաչման բացակայության դեպքում կիրառվում է  "Դաբաղով վարակված երկրից կամ գոտուց ներմուծման վերաբերյալ առաջարկությունների"      8.8.23  հոդվածը,որի համաձայն՝ մսամթերքը պետք է մշակվի տեխնոլոգիայի միջոցով, որն ապահովում է դաբաղ հիվանդության վիրուսի ոչնչացումը 8.8.31-րդ հոդվածում սահմանված 2 եղանակներից մեկով:  Համաձայն 8.31.  հոդվածի՝ նման ընթացակարգերը ներառում են աղակալումից հետո ապերտիզացիա, ստուգում կամ ջրազրկում:`,
                        `"Ռոսսելխոզնադզորը" թույլ է տալիս նշված առաջարկություններին համապատասխան չմշակված արտադրանքի ներմուծումը բացառապես Ռուսաստանի Դաշնության այն տարածքներ, որոնք ունեն պատվաստմամբ հսկողության գոտու կարգավիճակ:`,
                        `Ներկայումս Հայաստանից արտադրանքի արտահանումը կապված է զգալի համաճարակաբանական  ռիսկերի հետ (դաբաղի ներմուծումը Ռուսաստան), ինչի կապակցությամբ արտադրանքի ներմուծումը հնարավոր է միայն անհրաժեշտ սանիտարական ընթացակարգերի կատարման դեպքում(մշակմում):`,
                        `Սահմանափակումների վերացումը հնարավոր է, եթե Հայաստանը ստանա ԿԱՀԿ պաշտոնական կարգավիճակ՝ դաբաղից զերծ գոտի ունեցող երկրի ։`,
                        `ԵԱՏՄ շրջանակներում, հաշվի առնելով ԿԱՀԿ օրենսգրքի դրույթները՝ սահմանվել է անդամ պետությունների փոխգործակցության կարգը կենդանիների առանձնապես վտանգավոր, կարանտինային հիվանդությունների օջախների կանխարգելման, ախտորոշման, տեղայնացման և վերացման ժամանակ:`,
                    ],
                    category: 'ԱՏԳ. Ապրանքների ներմուծում Ռուսաստան',
                    tags: [
                        'Արտահանում',
                    ]
                },

                {
                    name: `Հնարավո՞ր է Ռուսաստանից արդյունաբերական ապրանքներ և արտադրանքներ արտահանել արտերկիր։`,
                    text: [
                        `<a href="https://base.garant.ru/403681896/" target="_blank">2022 թվականի մարտի 9-ի  Ռուսաստանի Դաշնության Կառավարության թիվ 311, 312, 313</a> որոշումները կարգավորում են ապրանքների և սարքավորումների արտահանման կարգը:`,
                        `Ներկայումս, օտարերկրյա արտադրանքի արտահանումը Ռուսաստանի սահմաններից դուրս արգելված է, բացառությամբ՝ ԵԱՏՄ տարածք, Աբխազիա և Հարավային Օսիա:`,
                        `Ռուսական արտադրության արտադրանքի նկատմամբ սահմանափակումներ չկան։ Ապրանքները կարող են արտահանվել ինչպես երրորդ երկրներ, այնպես էլ ԵԱՏՄ տարածք, եթե արկա է «ՍՏ-1» ձևի  հավաստագիր կամ ապրանքի ծագումը հաստատող այլ հավաստագիր, ինչպես նաև Ռուսաստանի  արդյունաբերության և առևտրի նախարարության համապատասխան եզրակացություն:`,
                    ],
                    category: 'ԱՏԳ. Ապրանքների արտահանում Ռուսաստանից',
                    tags: [
                        'ԱՏԳ',
                        'Ներմուծում',
                    ]
                },

                {
                    name: `Ինչպե՞ս է կազմակերպվում երթևեկությունը "Վերին Լարս"անցակետում։`,
                    text: [
                        `<a href="https://base.garant.ru/402826843/" target="_blank">Ռուսաստանի Դաշնության Կառավարության 2021 թվականի սեպտեմբերի 18-ի թիվ 1576 որոշմամբ՝</a> Վերին Լարս  միջազգային ավտոմոբիլային անցակետի  վերակառուցման շրջանակներում (Վերակառուցման ժամանակահատվածի համար, բայց ոչ ուշ, քան 2023 թվականի դեկտեմբերի 31-ը) սահմանվել են  ապրանքների ժամանակավոր գրանցման վայրեր այն ապրանքների համար, որոնք չեն հանդիսանում Ռուսաստանի Դաշնության սահմանով այդ ապրանքների տեղափոխման վայրեր, որոնք ներմուծվում կամ արտահանվում են ավտոմոբիլային տրանսպորտով (բացառությամբ կենդանիների) (1. Հյուսիսային Օսիայի Հանրապետություն-Ալանիա, Վերին Լարսի շրջանում, 2. Հյուսիսային Օսիայի Հանրապետություն-Ալանիա, ք. Վլադիկավկազ, Լարսի շրջանում ՝ "Վլադիկավկազ - Լարս"ա/ճ-ից 22 կմ աջ):`,
                        `Վերակառուցումից հետո սպասվում է, որ Վերին Լարս միջազգային ավտոմոբիլային անցակետի թողունակությունը կկազմի օրական մոտ՝ 3000 տրանսպորտային միջոց:`,
                        `2022թ. հունիսից պայմանավորվածություն է ձեռք բերվել բացել լրացուցիչ վայր, որտեղ թույլատրվում է "Վերին Լարս" ՄԱԱ - ով ավտոմոբիլային տրանսպորտով Ռուսաստանի Դաշնություն ներմուծվող և Ռուսաստանի Դաշնությունից արտահանվող ապրանքների (բացառությամբ կենդանիների) ժամանումն ու մեկնումը (Հյուսիսային Օսիայի Հանրապետություն - Ալանիա, ք. Վլադիկավկազ, Չմի գ., Դուդարով փ., 1ա., ինչպես նաև տվյալ հասցեում գտնվող պահեստային համալիրը)։`,
                        `"Հարավային դարպաս" ՍՊԸ-ն նախատեսվում է օգտագործել մինչև "Վերին Լարսի"վերակառուցման ամբողջական ավարտը:`,
                        `<b>Մաքսային ձևակերպումը բաղկացած է 5 հիմնական փուլից․</b>`,
                        `<ul>
                           <li>Մաքսային հայտարարագրի (ՄՀ)գրանցում</li>
                           <li>Փաստաթղթային հսկողություն , որի ընթացքում կատարվում է հայտարարատուի ներկայացրած բոլոր փաստաթղթերի ստուգում ՝ ԱՀ, մաքսային արժեքի հայտարարագիր, մաքսային արժեքի ճշգրտում,ինչպես նաև ստուգվում են  «Արտաքին տնտեսական գործունեության ապրանքային անվանացանկ» (ԱՏԳԱԱ) ծածկագրերը եւ ապրանքի ծագման երկիրը</li>
                           <li>Մաքսային մարմինների կողմից իրականացվող արժույթային հսկողությունը, որի ընթացքում ստուգվում են ֆինանսական փաստաթղթերը, այդ թվում ՝ պայմանագիրը եզակի համարով (1 օրվա ընթացքում ): Ստուգվում է նաև մաքսային վճարների ճշտգրությունը</li>
                           <li>Մաքսային վճարների վճարում (տուրքեր, մաքսավճարներ, հարկեր, լիցենզիաների տրամադրման վճարներ և բեռների մաքսային ձևակերպման ժամանակ օրենսդրությամբ նախատեսված այլ վճարներ)։</li>
                        </ul>`,
                        `Մաքսային հսկողության գծի անցում, որտեղ տեղի է ունենում մաքսազերծման ճշտգրտության վերջնական ստուգումը:`
                    ],
                    category: `ԱՏԳ. Տրանսպորտային լոգիստիկա`,
                    tags: [
                        'Լոգիստիկա',
                        'Տրանսպորտ',
                    ]
                },

                {
                    name: `Ռուսաստանի և Հայաստանի միջև ավիափոխադրումների սակագների հետ կապված իրավիճակի մասին։`,
                    text: [
                        `Կողմերը պայմանավորվածություն են ձեռք բերել Ռուսաստանի Դաշնության և Հայաստանի Հանրապետության տարածքում ժամանման կետերի քանակի, նշանակված ավիափոխադրողների քանակի և կատարվող թռիչքների հաճախականությունների, ինչպես նաև կողմերի ավիաընկերությունների համար օգտագործվող օդանավերի տեսակների տարողունակության վերացման վերաբերյալ:`,
                        `Սակագների և դրույքաչափերի համապատասխանեցման  հարցերը քննարկվում են Եվրասիական տնտեսական հանձնաժողովի հարթակում ՝ 2021-2023 թվականների համակարգված տրանսպորտային քաղաքականության հիմնական ուղղությունների և փուլերի իրականացման շրջանակներում:`,
                    ],
                    category: 'ԱՏԳ. Տրանսպորտային լոգիստիկա',
                    tags: [
                        'Ավիահաղորդակցություն',
                        'Լոգիստիկա',
                        'Տրանսպորտ',
                    ]
                },

                {
                    name: `Ինչպե՞ս է տեղի ունենում թանկարժեք բեռների մաքսազերծումը:`,
                    text: [
                        `Դեպի ԵԱՏՄ երկրներ ուղիղ չվերթների առկայության դեպքում արժեքավոր բեռները չեն հայտարարագրվում և չեն  կապարակնքվում, քանի որ ԵԱՏՄ ներսում բեռների տեղափոխման ժամանակ բացառված է դրանց մաքսային ձևակերպման ընթացակարգը, ինչը զգալիորեն հեշտացնում է փոխադրումների կազմակերպումը:`,
                        `Միջազգային ընդունված  նորմերի համապատասխան ՝ արժեքավոր ապրանքների  փոխադրումն իրականացվում է ավիացիոն տրանսպորտով, մասնավորապես, ավիաընկերությունների կանոնավոր չվերթներով, որոնց ինքնաթիռներն ունեն անհրաժեշտ պայմաններ արժեքավոր բեռների փոխադրման համար, ներառյալ ապահովագրական ծածկույթը:`,
                    ],
                    category: 'ԱՏԳ. Մաքսային ձևակերպում',
                    tags: [
                        'Ապրանքների հասանելիություն',
                        'Մաքսային ձևակերպում',
                        'Լոգիստիկա',
                        'Տրանսպորտ',
                    ]
                },

                {
                    name: `Ինչպե՞ս ստանալ դեղերի արտադրության թույլտվություն (լիցենզիա):`,
                    text: [
                        `Ռուսաստանի տարածքում դեղերի արտադրության լիցենզավորումն իրականացվում է Ռուսաստանի արդյունաբերության նախարարության կողմից, ներառյալ էլեկտրոնային ձևով:`,
                        `Ծառայությունը ստանալու նպատակով իրավաբանական անձը Ռուսաստանի արդյունաբերության նախարարություն (դեղագործական և բժշկական արդյունաբերության զարգացման վարչություն) տրամադրում  է հետևյալ փաստաթղթերը`,
                        `<ul>
                            <li>լիցենզիա տրամադրելու մասին դիմում</li>
                            <li>անհրաժեշտ տարածքների, շենքերի, շինությունների, տեխնիկական միջոցների առկայության հիմնավորումը</li>
                            <li>արդյունաբերական կանոնակարգերի տիտղոսաթերթերի պատճենները</li>
                            <li>փաստաթղթերի գույքագրում:</li>
                        </ul>`,
                        `<strong>Լիցենզիան տրամադրվում է 45 օրվա ընթացքում: Ծառայության արժեքը՝ 7500 ռուբլի:</strong>`,
                        `Իրավաբանական անձին լիցենզիայի տրամադրումը կարող է մերժվել այն դեպքերում, եթե  ներկայացվել է ոչ հավաստի կամ խեղաթյուրված տեղեկատվություն.հայտատուն չի համապատասխանում պահանջներին:`,
                        //`<strong>Более подробная информация:</strong><br/><a href="https://minpromtorg.gov.ru/activities/services/licensing/1_8/1_8_2/м" target="_blank">https://minpromtorg.gov.ru/activities/services/licensing/1_8/1_8_2/м</a>`,
                    ],
                    category: 'Գործարարություն  Ռուսաստանում: Թույլատրող փաստաթղթեր',
                    tags: [
                        'Տեղայնացում ',
                        'Արտադրություն',
                    ]
                },

                {
                    name: `Ինչպե՞ս գտնել պոտենցիալ մատակարար Ռուսաստանից:`,
                    text: [
                        `Դուք կարող եք գտնել ռուսական պոտենցիալ մատակարարներին՝ օգտագործելով MSP.RF թվային հարթակը։`,
                        `<b>Ինչ է՞ պետք անել:</b>`,
                        `Ա) Գրանցվել <a href="https://мсп.рф" target="_blank">MSP.RF թվային հարթակում</a>`,
                        `Բ) ընտրել <a href="https://xn--l1agf.xn--p1ai/services/development" target="_blank">"Արտադրական համագործակցություն և վաճառք"ծառայությունը:</a>`,
                        `Ծառայությունը ունի  2 գրանցամատյան, որտեղ Դուք կարող եք գտնել պոտենցիալ մատակարարներին և կապ հաստատել նրանց հետ:`,
                        `<ul>
                            <li>"Արդյունաբերական ընկերությունների ռեեստրը" պարունակում է տեղեկություններ ոչ պարենային ապրանքների և արդյունաբերական արտադրանքի ռուսաստանյան արտադրողների մասին, որոնք ունեն մատակարարումների հաստատված փորձ <a href="https://мсп.рф/services/development/not-food/" target="_blank">https://мсп.рф/services/development/not-food/</a></li>
                            <li>«Սննդամթերքի մատակարարների ռեեստրը» պարունակում է տեղեկություններ  ստուգված ռուս սննդամթերքի մատակարարների և արտադրողների մասին <a href="https://мсп.рф/services/development/food/" target="_blank">https://мсп.рф/services/development/food/</a></li>
                        </ul>`,
                        `Գ) Դուք նաև կարող եք  տեղադրել Ձեր մատակարարների որոնման հարցումը MSP.RF թվային հարթակում: Դրա համար անհրաժեշտ է լրացնել ձևաթուղթը Excel ձևաչափով ըստ նմուշի (կցվում է) և այն ուղարկել " MSP կորպորացիա "ԲԸ աշխատակիցների էլ․  փոստին՝ <a href="mailto:b2b@corpmsp.ru">b2b@corpmsp.ru</a>. Մենք կտեղադրենք Ձեր հարցումը ծառայության հարթակում և կուղղորդենք հնարավոր մատակարարների արձագանքները:`,
                    ],
                    category: 'Գործարարություն  Ռուսաստանում: Բիզնես հաղորդակցություն',
                    tags: [
                        'Արտադրական համագործակցություն և վաճառք',
                    ]
                },

                {
                    name: `Ինչպե՞ս գտնել պոտենցիալ գնորդ Ռուսաստանում:`,
                    text: [
                        `Դուք կարող եք գտնել Ռուսաստանի խոշոր պետական և մասնավոր ընկերությունների / առևտրային ցանցերի ընթացիկ հարցումները՝ օգտագործելով MSP.RF թվային հարթակը և դիմել խորհրդատվություն համար  "MSP կորպորացիա" ԲԸ աշխատակիցներին։`,
                        `<strong>Խոշոր պետական ընկերությունների կողմից հայտարարված  գնումները գտնելու համար անհրաժեշտ է:</strong>`,
                        `Ա) Գրանցվել <a href="https://мсп.рф" target="_blank">MSP.RF թվային հարթակում</a>`,
                        `Բ)Ընտրել <a href="https://xn--l1agf.xn--p1ai/services/purchase_access/" target="_blank">"Խոշոր պետական ընկերությունների գնումների հասանելիություն"</a> ծառայությունը`,
                        `Ծառայությունը կօգնի Ձեզ գտնել խոշոր պետական ընկերությունների կողմից հայտարարված գնումները:`,
                        `<i>Եթե գտել եք Ձեզ հետաքրքրող գնումը, ապա կապ հաստատեք " MSP կորպորացիա "ԲԸ-ի աշխատակցի հետ ՝8 800 1001100 հեռախոսահամարով (լրացուցիչ. 160, 468, 397) կամ նամակ ուղարկեք  էլ․ փոստով՝
                          <a href="mailto:b2b@corpmsp.ru">b2b@corpmsp.ru</a>.</i>`,
                        `<strong>Եթե ցանկանում եք ապրանքներ մատակարարել Ռուսաստանում մասնավոր ընկերություններին և առևտրային ցանցերին, ապա Ձեզ հարկավոր է:</strong>`,
                        `Ա) Գրանցվել <a href="https://мсп.рф" target="_blank">MSP.RF թվային հարթակում</a>`,
                        `Բ) ընտրել <a href="https://xn--l1agf.xn--p1ai/services/development" target="_blank">"Արտադրական համագործակցություն և վաճառք" ծառայությունը</a>`,
                        `Եթե  ցանկանում եք ոչ պարենային և արդյունաբերական ապրանքներ մատակարարել առևտրային ցանցերին և խոշոր արտադրական ընկերություններին, ապա ընտրեք հետևյալ մոդուլը՝ <a href="https://мсп.рф/services/development/retail_noprod/" target="_blank">«Արդյունաբերական ընկերություններ. դառնալ  մատակարար ՝մանրածախ վաճառողների և արտադրողների համար»</a>։`,
                        `Եթե ցանկանում եք մատակարարել սննդամթերք և հայտնվել խոշորագույն մանրածախ ցանցերի և <a href="https://мсп.рф/services/development/retail/" target="_blank">«Ֆերմերային կղզիների» դարակներում, ապա ընտրեք «Սննդամթերքի մատակարարների համար. հայտնվել մանրածախ ցանցերի դարակներում»</a> մոդուլը:`,
                        ``,
                        `<i>Եթե գտել եք Ձեզ հետաքրքրող հարցումը, ապա կապ հաստատեք "MSP կորպորացիա" ԲԸ-ի աշխատակցի հետ՝ 8 800 100 1 100 հեռախոսահամարով (ներքին 160, 468, 397) կամ ուղարկեք էլ․նամակ՝ <a href="mailto:b2b@corpmsp.ru">b2b@corpmsp.ru</a> հասցեով:</i>`,
                    ],
                    category: 'Գործարարություն  Ռուսաստանում: Բիզնես հաղորդակցություն',
                    tags: [
                        'Արտադրական համագործակցություն և վաճառք',
                        'Խոշոր պետական ընկերությունների կողմից հայտարարված գնումների հասանելիություն',
                    ]
                },
            ],
        },

        seminar2: {
            about: {
                title: 'ԸՆԿԵՐՈՒԹՅԱՆ ԱՆՁՆԱԿԱԶՄԻ ԿԱՌԱՎԱՐՄԱՆ ԱՐԴՅՈՒՆԱՎԵՏ ՀԱՄԱԿԱՐԳ',
                subtitle: 'ԵՐԿՕՐՅԱ ՍԵՄԻՆԱՐ ՀԱՅԿԱԿԱՆ ՁԵՌՆԱՐԿՈՒԹՅՈՒՆՆԵՐԻ ՂԵԿԱՎԱՐՆԵՐԻ ՀԱՄԱՐ',
                date: '2023 ԹՎԱԿԱՆԻ ՄԱՐՏԻ 17-18-Ը',
                desc: 'Հայաստանի իրավասությունների ազգային կենտրոնը` կազմակերպված «Հայաստանի Հանրապետության ռազմավարական զարգացման կենտրոն» հիմնադրամի կողմից` Հայաստանի տնտեսության աշխատանքի արտադրողականության բարձրացման ծրագրի շրջանակներում, Կորպորատիվ կառավարման բարձրագույն դպրոցի (ВШКУ РАНГХиГС) և Հայաստանի պետական տնտեսագիտական համալսարանի հետ համատեղ, հայտարարում է մասնակիցների հավաքագրում` «ԸՆԿԵՐՈՒԹՅԱՆ ԱՆՁՆԱԿԱԶՄԻ ԿԱՌԱՎԱՐՄԱՆ ԱՐԴՅՈՒՆԱՎԵՏ ՀԱՄԱԿԱՐԳ» թեմայով երկօրյա ինտենսիվ սեմինարի մասնակցության համար։',
                list: [
                    'Ժամանակակից բիզնեսի մրցակցային առավելություններն են դարձել կառավարչական ճկունությունը, անընդհատ փոփոխվող միջավայրին հարմարվելու ունակությունը, նորարարական լուծումներ ստեղծելու և արագ իրականացնելու կարողությունը:',
                    'Այս նպատակներին հասնելու գործում առանցքային դեր են խաղում աշխատակիցները, քանի որ նրանք հանդիսանում են կազմակերպության արժեքավոր կապիտալը:',
                    'Աշխատակիցների կառավարման արդյունավետ համակարգը, որը հիմնված է լիազորությունների և պատասխանատվության պատվիրակման վրա, մշակվել և ակտիվորեն կիրառվում է Գերմանիայում: Այն համատեղում է ճկունությունը և կառավարչական կարգը, փոխակերպում է բիզնես գործընթացները, բարձրացնում աշխատանքի արտադրողականությունը, նպաստում է աշխատակիցների թաքնված ունակությունների բացահայտմանը և խթանում նորարարությունը: Այս մոտեցումն ընկած է եվրոպական առաջատար ընկերությունների կառավարման հիմքում:'
                ],
                btn: `Գրանցվել`
            },
            objectives: {
                title: `ՍԵՄԻՆԱՐԻ ՆՊԱՏԱԿՆԵՐԸ ԵՎ ԽՆԴԻՐՆԵՐԸ`,
                list: [
                    'ԱՇԽԱՏԱԿԻՑՆԵՐԻ ԿԱՌԱՎԱՐՄԱՆ ՀԻՄՆԱԿԱՆ ԽՆԴԻՐՆԵՐԻ ԲԱՑԱՀԱՅՏՈՒՄ',
                    'ԼԻԱԶՈՐՈՒԹՅՈՒՆՆԵՐԻ ՊԱՏՎԻՐԱԿՄԱՆ ԱՐԴՅՈՒՆԱՎԵՏ ՀԱՄԱԿԱՐԳԻ ՍՏԵՂԾՈՒՄ ՝ ՀԱՇՎԻ ԱՌՆԵԼՈՎ ԿԱԶՄԱԿԵՐՊՈՒԹՅԱՆ ՆՊԱՏԱԿՆԵՐՆ ԵՎ ԱՇԽԱՏԱԿԻՑՆԵՐԻ ԻՐԱՎԱՍՈՒԹՅՈՒՆՆԵՐԸ',
                    'ԽՆԴԻՐՆԵՐԻ ԼՈՒԾՄԱՆ ԸՆԹԱՑՔՈՒՄ ԱՇԽԱՏԱԿԻՑՆԵՐԻ ԱՆԿԱԽՈՒԹՅԱՆ ՄԱԿԱՐԴԱԿԻ ԲԱՐՁՐԱՑՈՒՄ',
                    'ԱՇԽԱՏԱԿԻՑՆԵՐԻ ՆԱԽԱՁԵՌՆՈՒԹՅԱՆ ԱՃ, ՆՈՐԱՐԱՐՈՒԹՅՈՒՆՆԵՐԻ ԶԱՐԳԱՑՈՒՄ "ՆԵՐՔԵՎԻՑ"',
                    'ԱՇԽԱՏԱԿԻՑՆԵՐԻ ՎԵՐԱՀՍԿՈՂՈՒԹՅԱՆ ԺԱՄԱՆԱԿԻ ԿՐՃԱՏՈՒՄ (ՄԻԱԺԱՄԱՆԱԿ ՆՎԱԶԵՑՆԵԼՈՎ ՊԱՏՎԻՐԱԿՈՒԹՅԱՆ ՌԻՍԿԵՐԸ)',
                    'ԱՇԽԱՏԱԿԻՑՆԵՐԻ ՆԵՐԳՐԱՎՎԱԾՈՒԹՅԱՆ ԲԱՐՁՐԱՑՈՒՄ ՀԱՂՈՐԴԱԿՑՄԱՆ ՏԵԽՆԻԿԱՅԻ և ՈՉ ՆՅՈՒԹԱԿԱՆ ՄՈՏԻՎԱՑԻԱՅԻ ԳՈՐԾԻՔՆԵՐԻ ՄԻՋՈՑՈՎ',
                    'ԹԻՄՈՒՄ ՄԹՆՈԼՈՐՏԻ ԲԱՐԵԼԱՎՈՒՄ ԵՎ ԿՈԼԵԳԻԱԼ ՀԱՄԱԳՈՐԾԱԿՑՈՒԹՅԱՆ ԱՄՐԱՊՆԴՈՒՄ',
                    'ԱՇԽԱՏԱԿԻՑՆԵՐԻ ԹԻՄԱՅԻՆ ԱՇԽԱՏԱՆՔԻ ՀՄՏՈՒԹՅՈՒՆՆԵՐԻ ԶԱՐԳԱՑՈՒՄ',
                    'ՌԱԶՄԱՎԱՐԱԿԱՆ ԽՆԴԻՐՆԵՐԻ ԼՈՒԾՄԱՆ ՆՊԱՏԱԿՈՎ ՂԵԿԱՎԱՐՆԵՐԻ ԺԱՄԱՆԱԿԻ ԱՎԵԼԱՑՈՒՄ Ի ՀԱՇԻՎ ԳՈՐԾԱՌՆԱԿԱՆ ԳՈՐԾՈՒՆԵՈՒԹՅԱՆԸ ՏՐԱՄԱԴՐՎՈՂ ԺԱՄԱՆԱԿԻ ԿՐՃԱՏՄԱՆ',
                    'ԱՇԽԱՏԱՆՔԻ ԱՐՏԱԴՐՈՂԱԿԱՆՈՒԹՅԱՆ ԵՎ ԿԱԶՄԱԿԵՐՊՈՒԹՅԱՆ ՄՐՑՈՒՆԱԿՈՒԹՅԱՆ ԱՃ',
                ]
            },
            plan: {
                title: `ՍԵՄԻՆԱՐԻ ԾՐԱԳԻՐ`,
                list: [
                    'Եվրոպական կառավարման հիմունքներ. Լիազորությունների և պատասխանատվության պատվիրակման արդյունավետ համակարգ կառուցելու սկզբունքներն ու գործիքները',
                    'Հաղորդակցությունը կառավարման մեջ. խորհրդակցություններ, քննադատական և խրախուսական զրույցներ, բողոքների կարգավորում, ոչ նյութական մոտիվացիայի գործիքներ',
                    'Իրազեկում և կոլեգիալ համագործակցություն կազմակերպությունում',
                    'Կառավարման վերահսկողություն` հիմնված լիազորությունների պատվիրակման վրա'
                ],
                btn: `Գրանցվել`
            },
            info: {
                title: 'Ո՞ւՄ ՀԱՄԱՐ Է ՆԱԽԱՏԵՍՎԱԾ ՍԵՄԻՆԱՐԸ',
                desc: 'Սեմինարը նախատեսված է հայկական ձեռնարկությունների ղեկավարների, ղեկավարների տեղակալների, ստորաբաժանումների, գերատեսչությունների և բաժինների ղեկավարների համար։',
                list: [
                    {
                        icon: `calendar.svg`,
                        name: `ՍԵՄԻՆԱՐԻ ԱՆՑԿԱՑՄԱՆ <br/> ԱՄՍԱԹԻՎԸ ԵՎ ԺԱՄԸ`,
                        text: [
                            `<b>2023 թվականի մարտի 17-ին և 18-ին</b>`,
                            `<b>10:00–18:00</b>`
                        ]
                    },
                    {
                        icon: `point.svg`,
                        name: `ՍԵՄԻՆԱՐԻ ԱՆՑԿԱՑՄԱՆ <br/> ՎԱՅՐԸ`,
                        text: [
                            `Հայաստանի պետական տնտեսագիտական համալսարանի գլխավոր մասնաշենք`,
                            `<b>Երևան, Միքայել Նալբանդյան 128</b>`
                        ]
                    },
                    {
                        icon: `tooltip.svg`,
                        name: `ՀԱՏՈՒԿ ՊԱՅՄԱՆՆԵՐ `,
                        text: [
                            `Սեմինարը անցկացվելու <br/> է ռուսերեն լեզվով`,
                            `Մասնակիցները կստանան սեմինարի <br/> հեղինակային նյութերը`
                        ]
                    }
                ],
                disclaimer: `Սեմինարի մասնակցության արժեքը մեկ անձի համար կազմում է <span><strong>80.000 դրամ</strong></span>։ <br/>Վճարումը կատարվում է անկախիկ փոխանցումով՝ համաձայնագրի հիման վրա։`,
                btn: `Գրանցվել`
            },

            schedule: {
                title: 'ԺԱՄԱՆԱԿԱՑՈՒՅՑ',
                list: [
                    {
                        date: 'ՄԱՐՏԻ 17, ՕՐ ԱՌԱՋԻՆ',
                        content: [
                            {
                                time: '10.00 – 11.30',
                                text: [
                                    `Լիազորությունների և պատասխանատվության պատվիրակման վրա հիմնված կառավարման համակարգի ներածություն: Աշխատակիցների կառավարման եվրոպական մոտեցման առավելությունները և դրա տեղայնացման հնարավորությունը հայկական ընկերություններում:`,
                                    `Կազմակերպությունում կառավարման խնդիրների ախտորոշում:`,
                                ]
                            },

                            {
                                time: '12.00 – 13.30',
                                text: [
                                    `Լիազորությունների և պատասխանատվության պատվիրակման վրա հիմնված կառավարման մոդելի հիմնական հասկացություններն ու սկզբունքները: Պատվիրակումը\` որպես աշխատակիցների ներգրավվածության բարձրացման գործիք:`,
                                    `Սեմինարի մասնակիցների գործնական իրավիճակների վերլուծություն, հարցերի պատասխան։`,
                                ]
                            },

                            {
                                time: '14.30 – 16.00',
                                text: [
                                    `Սովորական և հատուկ դեպքեր կառավարման ընթացքում: Վերահսկողությունը՝ որպես լիազորությունների պատվիրակման ռիսկերը նվազագույնի հասցնելու գործիք: Կազմակերպությունում տեղեկատվական և կոլեգիալ համագործակցության հիմունքները:`,
                                    `Դեպք 1. «Կոմերցիոն բաժնի ղեկավարը բարդ իրավիճակում է»:`,
                                    `Դեպք 2. «Չհամաձայնեցված գործողությունները կարող են վնաս պատճառել»:`,
                                ]
                            },

                            {
                                time: '16.15 – 18.00',
                                text: [
                                    `Հաստիքի նկարագրությունը և կառավարման ընդհանուր ցուցումները՝ որպես մենեջմենթի փոփոխության գործիքներ: Ղեկավարի սխալները լիազորությունների պատվիրակման ժամանակ:`,
                                    `<span>Թրեյնինգ. Աշխատակիցներին լիազորությունների պատվիրակման սխալներ:</span>`,
                                    `Դեպք 3. «Մարքեթինգի բաժնի ղեկավարը չի ցանկանում պատասխանատվություն կրել»:`,
                                    `<span>Քննարկում. Ղեկավարի, աշխատակցի և կազմակերպության համար լիազորությունների պատվիրակման առավելություններն ու ռիսկերը:</span>`,
                                    `Մասնակիցների հարցերի քննարկում:`
                                ]
                            },
                        ]
                    },
                    {
                        date: 'ՄԱՐՏԻ 18, ՕՐ ԵՐԿՐՈՐԴ',
                        content: [
                            {
                                time: '10.00 – 11.30 ',
                                text: [
                                    `Ղեկավարի և աշխատակիցների միջև հաղորդակցությունը: Կառավարման մեջ հաղորդակցության առանձնահատկությունները և կանոնները\` լիազորությունների պատվիրակման հիման վրա: Խորհրդակցություններ կառավարման պրակտիկայում. խորհրդատվական և աշխատանքային հանդիպումներ:`,
                                    `<span>Մասնակիցների գործնական իրավիճակների քննարկում և վերլուծություն. «Հանդիպումների ընթացքում սխալները և կազմակերպությունում հանդիպումների արդյունավետության բարելավման մեթոդները»:</span>`,
                                    `Դեպք 4. «Հանդիպումները կառավարման մեջ»:`,
                                ]
                            },

                            {
                                time: '12.00 – 13.30 ',
                                text: [
                                    `<span>Թրեյնինգ. աշխատակիցների հետ խորհրդատվական հանդիպման անցկացում. Խմբային աշխատանք, արդյունքների ներկայացում:</span>`,
                                ]
                            },

                            {
                                time: '14.30 – 16.00',
                                text: [
                                    `Գործնական զրույցներ աշխատակիցների հետ. քննադատություն և խրախուսում: Ոչ նյութական մոտիվացիան և նորարարության խթանումը\` որպես կառավարման կարևոր գործիք: Բողոքների լուծման տեխնիկա:`,
                                    `<span>Թրեյնինգ. աշխատակցի հետ քննադատական զրույց:</span>`,
                                    `<span>Թրեյնինգ. աշխատակցի հետ խրախուսական զրույց:</span>`,
                                ]
                            },

                            {
                                time: '16.15 – 18.00 ',
                                text: [
                                    `Վերահսկողություն կառավարման մեջ\` լիազորությունների պատվիրակման հիման վրա: Վերահսկողության սկզբունքները, բովանդակությունը և շրջանակը: Աշխատանքի արդյունքների ընտրովի վերահսկում: Մասնագիտական և կառավարչական գործունեության վերահսկում: Վերահսկողության մեթոդները և ձևերը: Ստուգումների ծրագիր և վերահսկողության հաշվետվություն:`,
                                    `<span>Թրեյնինգ (խմբային աշխատանք)՝ աշխատողի մասնագիտական և կառավարչական գործունեության մոնիտորինգի ծրագրի մշակում:</span>`,
                                    `Դեպք 4. «Հեշտ չէ լինել տնօրեն»:`,
                                    `Սեմինարի արդյունքների ամփոփում, մասնակիցների հարցեր:`,
                                ]
                            },
                        ]
                    },
                ],
                btn: `Գրանցվել`
            },

            registration: {
                placeholders: {
                    surname: 'Ազգանուն *',
                    name: 'Անուն *',
                    email: 'Էլեկտրոնային հասցե *',
                    phone: 'Հեռախոս',
                    company: 'Կազմակերպություն',
                    position: 'Պաշտոն',
                },

                messages: {
                    empty: 'Պարտադիր դաշտ:',
                    email: 'Էլ. փոստը պետք է նման լինի example@domain.com-ին:',
                    phone: 'Թույլատրվում են միայն + և թվեր:'
                },

                result: {
                    success: `Շնորհակալություն! <br/> Ձեր հաղորդագրությունն ուղարկված է`,
                    error: `Սխալ առաջացավ, <br/> խնդրում ենք կրկին փորձեք:`
                },

                checkbox: 'Համաձայն եմ անձնական տվյալներիս մշակմանը և համաձայն եմ գաղտնիության քաղաքականության հետ',
                btn: `Գրանցվել`,
                curator: `ԾՐԱԳՐԻ ՀԱՄԱԿԱՐԳՈՂ ՝ <b>ԴԻԱՆԱ ԱՐՄԱՂԱՆՅԱՆ</b>  +374 99 342626 | education@csda.am`
            },
            partners: {
                list: [
                    {
                        img: `partner1.svg`
                    },

                    {
                        img: `partner2.svg`
                    },

                    {
                        img: `partner3.svg`
                    },

                    {
                        img: `partner4.svg`
                    },

                    {
                        img: `partner5.svg`
                    },

                ]
            }
        },

        exhibitions: {
            title: `2023 ԹՎԱԿԱՆԻ ՀԱՅԱՍՏԱՆԻ ՑՈՒՑԱՀԱՆԴԵՍՆԵՐԻ ՕՐԱՑՈՒՅՑԸ`,
            desc: `Ամեն տարի Հայաստանում անցկացվում են միջազգային խոշոր ցուցահանդես-տոնավաճառներ, որտեղ մասնակցում են կազմակերպություններ ամբողջ աշխարհից։ Ցուցահանդեսները հնարավորություն են տալիս ընդլայնել հաճախորդների բազան և խթանել վաճառքը, գտնել արտադրողական գործընթացների օպտիմալացման ուղիները, բարձրացնել մրցունակությունը, ապահովել ապրանքանիշի կամ ընկերության  նկատմամբ դրական ընկալումը և բարձրացնել գործարար համբավը։`,
            list: [
                {
                    date: `2023 թվականի մարտի  10-12-ը`,
                    name: `DIGITEC`,
                    link: `https://digitec.am`
                },

                {
                    date: `2023 թվականի մարտի  17-19-ը`,
                    name: `ԿՈՎԿԱՍ. ՇԻՆԱՐԱՐՈՒԹՅՈՒՆ և ՎԵՐԱՆՈՐՈԳՈՒՄ`,
                    link: `https://expo.am/ru/exhibition/kavkaz-stroitelstvo-i-remont-expo`
                },

                {
                    date: `2023 թվականի ապրիլի  5-7-ը`,
                    name: `ԿՐԹՈՒԹՅՈՒՆ և ԿԱՐԻԵՐԱ`,
                    link: `https://expo.am/ru/exhibition/obrazovanie-i-karera-expo`
                },

                {
                    date: `2023 թվականի մայիսի 19-21-ը`,
                    name: `ՇԻՆԱՐԱՐՈՒԹՅՈՒՆ և ԻՆՏԵՐԻԵՐ`,
                    link: `https://www.promexpo.am/exhibition.php?ex=cNi`
                },

                {
                    date: `2023 թվականի մայիսի 19-21-ը`,
                    name: `EXPO FOOD & DRINKS`,
                    link: `https://expo.am/ru/exhibition/expo-food-drinks`
                },

                {
                    date: `2023 թվականի հունիսի 2-4-ը`,
                    name: `ԼԵԴԻ EXPO`,
                    link: `https://expo.am/ru/exhibition/ledi-expo`
                },

                {
                    date: `2023 թվականի հունիսի 2-4-ը`,
                    name: `ՄԱՆԿՈՒԹՅԱՆ ԱՇԽԱՐՀ`,
                    link: `https://expo.am/ru/exhibition/mir-detstva-expo`
                },

                {
                    date: `2023 թվականի սեպտեմբերի 6-8-ը`,
                    name: `Պանարմենիան`,
                    link: `https://www.promexpo.am/index.php`
                },

                {
                    date: `2023 թվականի սեպտեմբերի 15-17-ը`,
                    name: `Armenia EXPO`,
                    link: `https://expo.am/ru/exhibition/armeniya-expo`
                },

                {
                    date: `2023 թվականի հոկտեմբերի 20-22-ը`,
                    name: `ArmProd EXPO 2023`,
                    link: `https://expo.am/armprod-expo`
                },

                {
                    date: `2023 թվականի նոյեմբերի 3-5-ը`,
                    name: `Առողջապահություն և դեղագործություն EXPO 2023`,
                    link: `https://expo.am/ru/exhibition/zdravoohranenie-i-farmaciya-expo`
                },
            ]
        },

        contacts: {
            title: 'Հետադարձ կապ',
            fond: {
                head: '«Հայաստանի Հանրապետության Ռազմավարական Զարգացման Կենտրոն» Հիմնադրամ',
                text: [
                    'info@csda.am',
                    '+37400000000',
                    'Մհեր Մկրտչյան 5, Երևան, 0010, Հայաստան'
                ]
            },
            vavt: {
                head: 'Կայքը պատրաստել է՝',
                text: [
                    {
                        content: 'Ինտեգրացիոն գործընթացների զարգացման ինստիտուտ',
                        link: 'http://www.vavt.ru/science/site/LSP6ED90B'
                    },
                    {
                        content: `Ռուսաստանի Դաշնության Տնտեսական Զարգացման Նախարարության Արտաքին Առևտրի համառուսական Ակադեմիայի Տվյալների Վերլուծության Կենտրոն`,
                        link: 'http://www.vavt.ru/science/site/cda'
                    }
                ]
            }
        }
    }
}

export default data;